import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';

import { LocalStoreService } from './local-store.service';

import { environment } from '../../../environments/environment';
import { UserService } from './user.service';
import { UserModel } from '../models/user.model';
import { WebsocketService } from './websocket.service';
import { CompanyModel } from '../models/company.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // Only for demo purpose
  triggered:boolean = false;
  initialTimeout:any;
  authenticated = false;
  token : any;
  isForgotPassword = false;
  user:UserModel;
  didFirstCheck:boolean = false;
  private companyLogo:string ="/assets/img/web-logo.png";

  constructor(
    private http: HttpClient,
     private store: LocalStoreService,
     private userService : UserService,
     private router : Router,
     private wsService : WebsocketService) {
    this.checkAuth();
  }

  timeout;

  // authenticate(){
  //   let _this = this;
  //   if(_this.timeout){
  //     clearTimeout(_this.timeout);
  //   }
  //   _this.timeout = setTimeout(() => {
  //     _this.userService.get(_this.getUserId()).subscribe(res => {
  //       _this.user = res['data'];
  //     },
  //     err => {
  //       _this.signout();
  //     });
  //   }, 350);
  // }

  getFavicon(){
    return "/assets/img/favicon.png";  
  }

  permissions:any[];

  hasPermission(permission_name: string) {
    if(!this.permissions){
      if(this.store.getItem('currentUser')){
        console.log(this.store.getItem('currentUser'));
        this.permissions = this.store.getItem('currentUser')['permissions'];
      }
    }
    if(this.permissions){
      if(this.permissions.includes(permission_name)){
        return true;
      }
    }
    else{
      this.signout();
    }
    return false;
  }

  getLogo(){
    return "/assets/img/logo.png";
  }

  getBackground(){
    return {'background':'url(/assets/img/login_screen.jpg)'};
  }

  checkAuth() {
    //this.authenticated = !!this.store.getItem('currentUser');
    if(!this.store.getItem('currentUser')){
      this.authenticated = false;
      //this.signout();
      return;
    }
    else{
      let _this = this;
      //perform Auth Check here.
      this.token = this.store.getItem('currentUser')['token'];
      // this.userService.get(this.store.getItem('currentUser')['id']).subscribe(res => {
      //   _this.didFirstCheck = true;
      //   _this.authenticated = true;
      //   _this.user = this.store.getItem('currentUser');
      //   _this.wsService.listenChannel("private-user-channel-",this.getUserId());
      // },
      // err => {
      //   _this.didFirstCheck = true;
      //   this.signout();
      // })
      _this.didFirstCheck = true;
      _this.authenticated = true;
      _this.user = this.store.getItem('currentUser');
    }
  }

  getUser() {
    return of(this.store.getItem('currentUser'));
  }

  getUserId(){
    const user : any = this.store.getItem('currentUser');
    if(!user){
      return null;
    }
    return user.id;
  }

  // getCompanyLogo(){
  //   return "/assets/img/logo.png";
  // }

  activeCompany:CompanyModel;
  getCompanyLogo(){
    let logo = ((this.activeCompany && this.activeCompany.image_path) ? (this.activeCompany.image_path ? this.activeCompany.image_path : "/assets/img/logo.png") : "/assets/img/logo.png");
    this.companyLogo = logo;
    //console.log(logo);
    return logo;
  }

  getSystemRoles(){
    return this.http.get(`${environment.baseUrl}/systemrole`);
  }

  getUserRole() {
    const user: any = this.store.getItem('currentUser');
    if(!user){
      return null;
    }
    if(!user.role){
      return null;
    }
    return user.role.id;
  }

  forgotToken(username){
    return this.http.post(`${environment.baseUrl}/auth/forgot/request`,{email:username});
  }

  forgotUpdate(formData){
    return this.http.post(`${environment.baseUrl}/auth/forgot/reset`,formData);
  }

  updateCredentials(formData){
    return this.http.put(`${environment.baseUrl}/user/profile`,formData);
  }

  signin(credentials) {
    return this.http.post<any>(`${environment.baseUrl}/auth/login/admin`, { email: credentials.email, password: credentials.password });
  }

  signout() {
    let _this = this;
    this.authenticated = false;
    this.didFirstCheck = false;
    this.activeCompany = null;
    _this.store.setItem('currentUser', null);
    _this.router.navigate(['/dashboard']);
  }
  
}