import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import * as FileSaver from "file-saver";
import * as moment from 'moment';

@Injectable({
  providedIn: "root",
})
export class Utils {

  //public didFirstLoad:boolean = false;

  constructor(private http: HttpClient) {}

  filterArrayByIds(idList: number[], array: any[]) {
    let filteredArray = array.filter((item) => !idList.includes(item.id));
    let mapValues: Map<number, any> = new Map<number, any>();
    for (let val of filteredArray) {
      mapValues.set(val.id, val);
    }
    return Array.from(mapValues.values());
  }

  filteredParams: any = {};
  filterUpdateTimeout: any;
  pushFiltersToRouter(value, name, filteredParams): EventEmitter<any> {
    let pusher = new EventEmitter<any>();
    let _this = this;
    this.filteredParams = filteredParams;
    value = encodeURIComponent(value);
    let paramStream = "?";
    for (let param in _this.filteredParams) {
      if (
        !_this.filteredParams[param] ||
        _this.filteredParams[param] == "null"
      ) {
        continue;
      }
      paramStream += param + "=" + _this.filteredParams[param] + "&";
    }
    if (_this.filterUpdateTimeout) {
      clearTimeout(_this.filterUpdateTimeout);
      _this.filterUpdateTimeout = null;
    }
    _this.filterUpdateTimeout = setTimeout(() => {
      pusher.emit(paramStream);
      //window.history.pushState(null,"",("transaction"+paramStream));
      clearTimeout(_this.filterUpdateTimeout);
      _this.filterUpdateTimeout = null;
    }, 500);
    return pusher;
  }

  getFormattedPeriod(startInput: Date, endInput: Date): string {
    if (moment(startInput).isSame(endInput, "year")) {
      if (moment(startInput).isSame(endInput, "M")) {
        if(moment(startInput).isSame(endInput, "D")){
          return (
            moment(endInput).format("DD MMMM YYYY")
          );
        }
        else{
          return (
            moment(startInput).format("DD") +
            " - " +
            moment(endInput).format("DD MMMM YYYY")
          );
        }
      } else {
        return (
          moment(startInput).format("DD MMMM") +
          " - " +
          moment(endInput).format("DD MMMM YYYY")
        );
      }
    } else {
      if (moment(startInput).isSame(endInput, "M")) {
        return (
          moment(startInput).format("DD") +
          " - " +
          moment(endInput).format("DD MMMM YYYY")
        );
      } else {
        let dateStart = moment(startInput).format("DD MMMM YYYY");
        let dateEnd = moment(endInput).format("DD MMMM YYYY");
        return dateStart + " - " + dateEnd;
      }
    }
  }

  formatNumbersToFixedWithCommas(number) {
    var amount = parseFloat(number).toFixed(2);
    var formattedString = amount
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return formattedString;
  }

  exportTableDownload(table, params) {
    table = table.toLowerCase();
    this.http
      .post(`${environment.baseUrl}/` + table + `/export`, params)
      .subscribe(
        (res) => {
          let f = res["data"];
          let csv = res["meta"]["columns"].join(",") + "\n";
          for (let row in f) {
            var rowArr = [];
            for (let key in f[row]) {
              rowArr.push(f[row][key]);
            }
            csv += rowArr.join(",") + "\n";
          }
          console.log(csv);
          const blob = new Blob([csv], { type: "text/plain" });
          console.log(f);
          FileSaver.saveAs(blob, table + ".csv");
        },
        (err) => {
          console.log(err);
        }
      );
  }

  submitToggle(
    activeActionTitle,
    messageService,
    searchParams,
    rows,
    tableName
  ) {
    let _this = this;
    switch (activeActionTitle) {
      case "Export Selected":
        messageService.remove();
        messageService.success("Exported Selected Records!", {
          Position: "bottom-right",
          Style: "simple",
          Duration: 5000,
        });
        let recordIds: number[] = [];
        console.log(rows);
        for (let row of rows) {
          recordIds.push(row.id);
        }
        let params = { ...searchParams, list_all: false, records: recordIds };
        this.exportTableDownload(tableName, params);
        break;
      case "Export All":
        messageService.remove();
        this.exportTableDownload(tableName, {
          ...searchParams,
          list_all: true,
          records: [],
        });
        messageService.success("Exported All Records!", {
          Position: "bottom-right",
          Style: "simple",
          Duration: 5000,
        });
        break;
      default:
        messageService.error("Please select a bulk action!", {
          Position: "bottom-right",
          Style: "simple",
          Duration: 5000,
        });
        break;
    }
  }

  colorList: any[] = ['#408FB1', '#00838f', '#BE675B', '#4F697D', '#41BBC4', '#F5A94B', '#00897b'];
  generateGraphData(isTrending,names,data) {
    let _this = this;
    let chart: any;
    if(isTrending){
      chart = {
          color: this.colorList,
          tooltip: {
              trigger: 'axis',
              axisPointer: {
                  type: 'cross',
                  label: {
                      backgroundColor: '#6a7985'
                  }
              }
          },
          toolbox: {
              feature: {
              }
          },
          grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
          },
          xAxis: [
              {
                  type: 'category',
                  //boundaryGap: false,
                  data: names,
                  axisLabel: {
                      interval: 0,
                      rotate: 0 //If the label names are too long you can manage this by rotating the label.
                  }
              }
          ],
          yAxis: [
              {
                  type: 'value',
                  //scale: true,
              }
          ],
          series: data
      };
  }
  else{
      chart = {
          color: this.colorList,
          tooltip: {
              trigger: 'axis',
              axisPointer: {
                  type: 'cross',
                  label: {
                      backgroundColor: '#6a7985'
                  }
              }
          },
          toolbox: {
              feature: {
              }
          },
          grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
          },
          xAxis: [
              {
                  type: 'category',
                  //boundaryGap: false,
                  data: names,
                  axisLabel: {
                      interval: 0,
                      rotate: 45 //If the label names are too long you can manage this by rotating the label.
                  }
              }
          ],
          yAxis: [
              {
                  type: 'value'
              }
          ],
          series: data
      };
  }
    return chart;
  }
}
