import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { Observable } from 'rxjs/Observable';
import * as Rx from 'rxjs/Rx';
import { environment } from '../../../environments/environment';
import { AuthService } from './auth.service';
import { LocalStoreService } from './local-store.service';


@Injectable({
    providedIn: 'root'
})
export class WebsocketService {

  // Our socket connection
  private socket;

  constructor(private localStorage : LocalStoreService) { }

  connect(): Rx.Subject<MessageEvent> {
    this.socket =  io(environment.ws_url, {
        transportOptions: {
            polling: {
                extraHeaders: {
                    //'Authorization': 'Bearer '+this.localStorage.getItem('currentUser')['token']
                }
            }
        }
    });

    let observable = new Observable(observer => {
        this.socket.on('new-asset-position', (data) => {
          console.log("Received message from Websocket Server");
          observer.next(data);
        })
        return () => {
          this.socket.disconnect();
        }
    });

    let observer = {
        next: (data: Object) => {
            this.socket.emit('message', JSON.stringify(data));
        },
    };

    return Rx.Subject.create(observer, observable);
  }

}