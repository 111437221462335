// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  baseUrl: 'https://api.umanaepr.codeblack.mt/api/v1',//api.dev.slik.mt
  per_page : 25,
  mapUrl:"https://maps.google.com/maps/api/js?key=AIzaSyCDvOZTRv0cAPm3-_Jq2OA8J_0R6wEG_-s",
  ws_url: "https://socket.track.waypoint.com.mt",
  dummy_data:[
    {
      "count": 65,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Attard Community Clinic",
      "clinic": "ATTARD COVID-19 VRS",
      "agenda": "ATTARD COVID VRS LIST",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 30,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Attard Community Clinic",
      "clinic": "ATTARD PODIATRY SERVICES",
      "agenda": "ATTARD PODIATRY CORE CLINIC",
      "exam": "Podiatry service"
    },
    {
      "count": 54,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC ANTICOAGULANT CLINIC",
      "agenda": "BHC ANTICOAGULANT CLINIC",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 12,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC ANTICOAGULANT CLINIC",
      "agenda": "BHC ANTICOAGULANT CLINIC",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 8,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC COVID-19 IMMUNISATION SERVICE",
      "agenda": "BHC ASTRAZENECA COVID IMMUNISATION",
      "exam": "COVID-19 vaccination"
    },
    {
      "count": 57,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC COVID-19 IMMUNISATION SERVICE",
      "agenda": "BHC ASTRAZENECA COVID IMMUNISATION",
      "exam": "COVID-19 vaccination"
    },
    {
      "count": 51,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC COVID-19 IMMUNISATION SERVICE",
      "agenda": "BHC COVID IMMUNISATION",
      "exam": "COVID-19 vaccination"
    },
    {
      "count": 47,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC COVID-19 IMMUNISATION SERVICE",
      "agenda": "BHC ASTRAZENECA COVID IMMUNISATION",
      "exam": "COVID-19 vaccination"
    },
    {
      "count": 22,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC COVID-19 VRS",
      "agenda": "BHC COVID VRS LIST",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 58,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC DIABETES CLINIC",
      "agenda": "BHC DR.C.RIZZO -FOLLOW UP",
      "exam": "Diabetes Consultant - Follow up"
    },
    {
      "count": 64,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC DIABETES CLINIC",
      "agenda": "BHC DR C RIZZO - NEW CASES",
      "exam": "Diabetes Consultant - New case"
    },
    {
      "count": 69,
      "status": "IN_PROGRESS",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC DIABETES CLINIC",
      "agenda": "BHC DR.C.RIZZO -FOLLOW UP",
      "exam": "Diabetes Consultant - Follow up"
    },
    {
      "count": 29,
      "status": "IN_PROGRESS",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC DIABETES CLINIC",
      "agenda": "BHC DR C RIZZO - NEW CASES",
      "exam": "Diabetes Consultant - New case"
    },
    {
      "count": 42,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC DIABETES CLINIC",
      "agenda": "BHC DR.C.RIZZO -FOLLOW UP",
      "exam": "Diabetes Consultant - Follow up"
    },
    {
      "count": 26,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC ECG SERVICE",
      "agenda": "BHC E.C.G. CLINIC",
      "exam": "Cardiovascular monitoring by ECG"
    },
    {
      "count": 91,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC ECG SERVICE",
      "agenda": "BHC E.C.G. CLINIC",
      "exam": "Cardiovascular monitoring by ECG"
    },
    {
      "count": 6,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC ECG SERVICE",
      "agenda": "BHC E.C.G. CLINIC",
      "exam": "Cardiovascular monitoring by ECG"
    },
    {
      "count": 54,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC GP CLINIC",
      "agenda": "BHC GP CLINIC",
      "exam": "Encounter for problem"
    },
    {
      "count": 39,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC GP CLINIC",
      "agenda": "BHC GP CLINIC",
      "exam": "Telephone consultation"
    },
    {
      "count": 67,
      "status": "FINISHED",
      "walk-in": true,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC GP CLINIC",
      "agenda": "BHC GP CLINIC",
      "exam": "Encounter for problem"
    },
    {
      "count": 74,
      "status": "FINISHED",
      "walk-in": true,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC GP CLINIC",
      "agenda": "BHC GP CLINIC",
      "exam": "Telephone consultation"
    },
    {
      "count": 41,
      "status": "IN_PROGRESS",
      "walk-in": true,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC GP CLINIC",
      "agenda": "BHC GP CLINIC",
      "exam": "Encounter for problem"
    },
    {
      "count": 84,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC MEDICAL CONSULTANT CLINIC",
      "agenda": "BHCMCC F/UPS (DR. AZZOPARDI)",
      "exam": "Medical consultant - Follow up"
    },
    {
      "count": 85,
      "status": "CANCELLED",
      "walk-in": true,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC MEDICAL CONSULTANT CLINIC",
      "agenda": "BHC  MCC N/CASES (DR.AZZOPARDI)",
      "exam": "Medical consultant - New case"
    },
    {
      "count": 98,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC MEDICAL CONSULTANT CLINIC",
      "agenda": "BHCMCC F/UPS (DR. AZZOPARDI)",
      "exam": "Medical consultant - Follow up"
    },
    {
      "count": 83,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC MEDICAL CONSULTANT CLINIC",
      "agenda": "BHC  MCC N/CASES (DR.AZZOPARDI)",
      "exam": "Medical consultant - New case"
    },
    {
      "count": 37,
      "status": "IN_PROGRESS",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC MEDICAL CONSULTANT CLINIC",
      "agenda": "BHCMCC F/UPS (DR. AZZOPARDI)",
      "exam": "Medical consultant - Follow up"
    },
    {
      "count": 70,
      "status": "ON_LEAVE",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC MEDICAL CONSULTANT CLINIC",
      "agenda": "BHC  MCC N/CASES (DR.AZZOPARDI)",
      "exam": "Medical consultant - New case"
    },
    {
      "count": 89,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC MEDICAL CONSULTANT CLINIC",
      "agenda": "BHCMCC F/UPS (DR. AZZOPARDI)",
      "exam": "Medical consultant - Follow up"
    },
    {
      "count": 46,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC MEDICAL CONSULTANT CLINIC",
      "agenda": "BHC  MCC N/CASES (DR.AZZOPARDI)",
      "exam": "Medical consultant - New case"
    },
    {
      "count": 47,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC PHYSIOTHERAPY SERVICES",
      "agenda": "BHC G.SCICLUNA FOLLOW UP",
      "exam": "Physiotherapy - Follow up"
    },
    {
      "count": 31,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC PHYSIOTHERAPY SERVICES",
      "agenda": "BHC G.SCICLUNA FOLLOW UP",
      "exam": "Physiotherapy - Follow up"
    },
    {
      "count": 40,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC PHYSIOTHERAPY SERVICES",
      "agenda": "BHC G.SCICLUNA NEW CASES",
      "exam": "Physiotherapy - New Case"
    },
    {
      "count": 37,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC PHYSIOTHERAPY SERVICES",
      "agenda": "BHC N.AGIUS - NEW",
      "exam": "Physiotherapy - New Case"
    },
    {
      "count": 65,
      "status": "IN_PROGRESS",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC PHYSIOTHERAPY SERVICES",
      "agenda": "BHC N.AGIUS - FOLLOW UP",
      "exam": "Physiotherapy - Follow up"
    },
    {
      "count": 61,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC PHYSIOTHERAPY SERVICES",
      "agenda": "BHC N.AGIUS - FOLLOW UP",
      "exam": "Physiotherapy - Follow up"
    },
    {
      "count": 71,
      "status": "UNARRIVED",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC PHYSIOTHERAPY SERVICES",
      "agenda": "BHC G.SCICLUNA NEW CASES",
      "exam": "Physiotherapy - New Case"
    },
    {
      "count": 71,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC PODIATRY SERVICES",
      "agenda": "PODIATRY CORE CLINIC 2",
      "exam": "Podiatry service"
    },
    {
      "count": 22,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC PODIATRY SERVICES",
      "agenda": "VASCULAR ASSESSMENT CLINIC 2",
      "exam": "Doppler ultrasound of vascular structure of lower limb"
    },
    {
      "count": 37,
      "status": "CANCELLED",
      "walk-in": true,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC PODIATRY SERVICES",
      "agenda": "PODOPAEDIATRICS CLINIC 1",
      "exam": "Podiatry service"
    },
    {
      "count": 65,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC PODIATRY SERVICES",
      "agenda": "VASCULAR ASSESSMENT CLINIC 2",
      "exam": "Doppler ultrasound of vascular structure of lower limb"
    },
    {
      "count": 58,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC PODIATRY SERVICES",
      "agenda": "BHC PODOLOGY CLINIC. EVENING",
      "exam": "Podiatry service"
    },
    {
      "count": 39,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC PODIATRY SERVICES",
      "agenda": "BIOMECHANICS & GAIT ANALYSIS CL 1",
      "exam": "Podiatry service"
    },
    {
      "count": 65,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC PODIATRY SERVICES",
      "agenda": "PODIATRY CORE CLINIC 1",
      "exam": "Podiatry service"
    },
    {
      "count": 57,
      "status": "FINISHED",
      "walk-in": true,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC PODIATRY SERVICES",
      "agenda": "PODIATRY CORE CLINIC 1",
      "exam": "Podiatry service"
    },
    {
      "count": 95,
      "status": "FINISHED",
      "walk-in": true,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC PODIATRY SERVICES",
      "agenda": "PODOPAEDIATRICS CLINIC 1",
      "exam": "Podiatry service"
    },
    {
      "count": 33,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC PODIATRY SERVICES",
      "agenda": "VASCULAR ASSESSMENT CLINIC 2",
      "exam": "Doppler ultrasound of vascular structure of lower limb"
    },
    {
      "count": 87,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC PODIATRY SERVICES",
      "agenda": "PODIATRIC SURGERY (NAIL AVULSIONS)",
      "exam": "Partial nail avulsion"
    },
    {
      "count": 74,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC PODIATRY SERVICES",
      "agenda": "PODIATRY CORE CLINIC 1",
      "exam": "Podiatry service"
    },
    {
      "count": 12,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC PODIATRY SERVICES",
      "agenda": "PODIATRY CORE CLINIC 2",
      "exam": "Podiatry service"
    },
    {
      "count": 72,
      "status": "IN_PROGRESS",
      "walk-in": true,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC PODIATRY SERVICES",
      "agenda": "PODOPAEDIATRICS CLINIC 1",
      "exam": "Podiatry service"
    },
    {
      "count": 36,
      "status": "IN_PROGRESS",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC PODIATRY SERVICES",
      "agenda": "PODIATRY CORE CLINIC 2",
      "exam": "Podiatry service"
    },
    {
      "count": 7,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC PODIATRY SERVICES",
      "agenda": "VASCULAR ASSESSMENT CLINIC 2",
      "exam": "Doppler ultrasound of vascular structure of lower limb"
    },
    {
      "count": 14,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC PODIATRY SERVICES",
      "agenda": "BHC PODOLOGY CLINIC. EVENING",
      "exam": "Podiatry service"
    },
    {
      "count": 99,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC PODIATRY SERVICES",
      "agenda": "PODIATRY CORE CLINIC 1",
      "exam": "Podiatry service"
    },
    {
      "count": 56,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC PODIATRY SERVICES",
      "agenda": "PODIATRY CORE CLINIC 2",
      "exam": "Podiatry service"
    },
    {
      "count": 31,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC SPEECH LANGUAGE SERVICES",
      "agenda": "BHC 854 SPEECH LANG.(MS.C.SCHEMBRI)",
      "exam": "Speech and language therapy service"
    },
    {
      "count": 8,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC SPEECH LANGUAGE SERVICES",
      "agenda": "BHC 854 SPEECH LANG.(MS.C.SCHEMBRI)",
      "exam": "Speech and language therapy service"
    },
    {
      "count": 7,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC SPEECH LANGUAGE SERVICES",
      "agenda": "BHC 854 SPEECH LANG.(MS.C.SCHEMBRI)",
      "exam": "Speech and language therapy service"
    },
    {
      "count": 91,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC TREATMENT ROOM",
      "agenda": "BHC TREATMENT ROOM NURSE",
      "exam": "Assessment of condition requiring immediate care"
    },
    {
      "count": 95,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC TREATMENT ROOM",
      "agenda": "BHC 450 BLOOD INVESTIGATIONS",
      "exam": "Venous blood sampling"
    },
    {
      "count": 28,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC TREATMENT ROOM",
      "agenda": "BHC ACC CL. 2",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 3,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC TREATMENT ROOM",
      "agenda": "BHC 450 BLOOD INVESTIGATIONS",
      "exam": "Venous blood sampling"
    },
    {
      "count": 94,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC TREATMENT ROOM",
      "agenda": "BHC SAMPLE COLLECTION - PRIORITY",
      "exam": "Venous blood sampling"
    },
    {
      "count": 69,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC TREATMENT ROOM",
      "agenda": "BHC 450 BLOOD INVESTIGATIONS",
      "exam": "Venous blood sampling"
    },
    {
      "count": 54,
      "status": "FINISHED",
      "walk-in": true,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC TREATMENT ROOM",
      "agenda": "BHC TREATMENT ROOM NURSE",
      "exam": "Assessment of condition requiring immediate care"
    },
    {
      "count": 78,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC TREATMENT ROOM",
      "agenda": "BHC EAR SYRINGING CLINIC",
      "exam": "Syringing of ear"
    },
    {
      "count": 60,
      "status": "IN_PROGRESS",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC TREATMENT ROOM",
      "agenda": "BHC EAR SYRINGING CLINIC",
      "exam": "Syringing of ear"
    },
    {
      "count": 62,
      "status": "IN_PROGRESS",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC TREATMENT ROOM",
      "agenda": "BHC 450 BLOOD INVESTIGATIONS",
      "exam": "Venous blood sampling"
    },
    {
      "count": 96,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC TREATMENT ROOM",
      "agenda": "BHC 450 BLOOD INVESTIGATIONS",
      "exam": "Venous blood sampling"
    },
    {
      "count": 83,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC WOUND MANAGEMENT CLINIC",
      "agenda": "BHC WOUND MANAGEMENT CLINIC 1",
      "exam": "Change of dressing"
    },
    {
      "count": 13,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC WOUND MANAGEMENT CLINIC",
      "agenda": "BHC WOUND MANAGEMENT CLINIC 1",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 32,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Birkirkara Health Centre",
      "clinic": "BHC WOUND MANAGEMENT CLINIC",
      "agenda": "BHC WOUND MANAGEMENT CLINIC 1",
      "exam": "Change of dressing"
    },
    {
      "count": 83,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Blood Bank (G'Mangia)",
      "clinic": "Blood Bank COVID-19 Immunisation Service",
      "agenda": "BLOOD BANK COVID IMMUNISATION",
      "exam": "COVID-19 vaccination"
    },
    {
      "count": 91,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Blood Bank (G'Mangia)",
      "clinic": "BLOOD BANK COVID-19 VRS",
      "agenda": "BLOOD BANK COVID VRS LIST",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 59,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Cospicua Health Centre",
      "clinic": "CHC ANTICOAGULANT CLINIC",
      "agenda": "CHC POINT OF CARE ACC",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 28,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Cospicua Health Centre",
      "clinic": "CHC ANTICOAGULANT CLINIC",
      "agenda": "CHC POINT OF CARE ACC",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 28,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Cospicua Health Centre",
      "clinic": "CHC COVID-19 IMMUNISATION SERVICE",
      "agenda": "CHC ASTRAZENECA COVID IMMUNISATION",
      "exam": "COVID-19 vaccination"
    },
    {
      "count": 1,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Cospicua Health Centre",
      "clinic": "CHC COVID-19 IMMUNISATION SERVICE",
      "agenda": "CHC ASTRAZENECA COVID IMMUNISATION",
      "exam": "COVID-19 vaccination"
    },
    {
      "count": 99,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Cospicua Health Centre",
      "clinic": "CHC COVID-19 IMMUNISATION SERVICE",
      "agenda": "CHC COVID IMMUNISATION",
      "exam": "COVID-19 vaccination"
    },
    {
      "count": 85,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Cospicua Health Centre",
      "clinic": "CHC COVID-19 IMMUNISATION SERVICE",
      "agenda": "CHC ASTRAZENECA COVID IMMUNISATION",
      "exam": "COVID-19 vaccination"
    },
    {
      "count": 58,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Cospicua Health Centre",
      "clinic": "CHC COVID-19 IMMUNISATION SERVICE",
      "agenda": "CHC COVID IMMUNISATION",
      "exam": "COVID-19 vaccination"
    },
    {
      "count": 17,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Cospicua Health Centre",
      "clinic": "CHC COVID-19 VRS",
      "agenda": "CHC COVID VRS LIST",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 15,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Cospicua Health Centre",
      "clinic": "CHC DIETETICS SERVICES",
      "agenda": "CHC  MS.I.PULIS - FUP",
      "exam": "Dietetic procedures"
    },
    {
      "count": 93,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Cospicua Health Centre",
      "clinic": "CHC DIETETICS SERVICES",
      "agenda": "CHC  MS.I.PULIS - FUP",
      "exam": "Dietetic procedures"
    },
    {
      "count": 10,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Cospicua Health Centre",
      "clinic": "CHC GP CLINIC",
      "agenda": "CHC 769 GP CLINIC",
      "exam": "Encounter for problem"
    },
    {
      "count": 58,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Cospicua Health Centre",
      "clinic": "CHC GP CLINIC",
      "agenda": "CHC 769 GP CLINIC",
      "exam": "Telephone consultation"
    },
    {
      "count": 74,
      "status": "FINISHED",
      "walk-in": true,
      "organization unit": "Cospicua Health Centre",
      "clinic": "CHC GP CLINIC",
      "agenda": "CHC 769 GP CLINIC",
      "exam": "Telephone consultation"
    },
    {
      "count": 24,
      "status": "IN_PROGRESS",
      "walk-in": true,
      "organization unit": "Cospicua Health Centre",
      "clinic": "CHC GP CLINIC",
      "agenda": "CHC 769 GP CLINIC",
      "exam": "Encounter for problem"
    },
    {
      "count": 28,
      "status": "IN_PROGRESS",
      "walk-in": true,
      "organization unit": "Cospicua Health Centre",
      "clinic": "CHC GP CLINIC",
      "agenda": "CHC 769 GP CLINIC",
      "exam": "Telephone consultation"
    },
    {
      "count": 6,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Cospicua Health Centre",
      "clinic": "CHC MEDICAL CONSULTANT CLINIC",
      "agenda": "CHC 315 MCC N/C (DR. M.FARRUGIA)",
      "exam": "Medical consultant - New case"
    },
    {
      "count": 11,
      "status": "FINISHED",
      "walk-in": true,
      "organization unit": "Cospicua Health Centre",
      "clinic": "CHC MEDICAL CONSULTANT CLINIC",
      "agenda": "CHC 315 MCC N/C (DR. M.FARRUGIA)",
      "exam": "Medical consultant - New case"
    },
    {
      "count": 17,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Cospicua Health Centre",
      "clinic": "CHC MEDICAL CONSULTANT CLINIC",
      "agenda": "CHC 315 MCC N/C (DR. M.FARRUGIA)",
      "exam": "Medical consultant - New case"
    },
    {
      "count": 66,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Cospicua Health Centre",
      "clinic": "CHC MEDICAL CONSULTANT CLINIC",
      "agenda": "CHC 315 MCC N/C (DR. M.FARRUGIA)",
      "exam": "Medical consultant - New case"
    },
    {
      "count": 99,
      "status": "FINISHED",
      "walk-in": true,
      "organization unit": "Cospicua Health Centre",
      "clinic": "CHC PHYSIOTHERAPY SERVICES",
      "agenda": "CHC PHYSIO TEAM CL 2 - FOLLOW UP",
      "exam": "Physiotherapy - Follow up"
    },
    {
      "count": 71,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Cospicua Health Centre",
      "clinic": "CHC PHYSIOTHERAPY SERVICES",
      "agenda": "CHC PHYSIO (M. XUEREB) - FOLLOW UP",
      "exam": "Physiotherapy - Follow up"
    },
    {
      "count": 32,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Cospicua Health Centre",
      "clinic": "CHC PHYSIOTHERAPY SERVICES",
      "agenda": "CHC PHYSIO TEAM CL 2 - FOLLOW UP",
      "exam": "Physiotherapy - Follow up"
    },
    {
      "count": 98,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Cospicua Health Centre",
      "clinic": "CHC PHYSIOTHERAPY SERVICES",
      "agenda": "CHC PHYSIO TEAM CL 2 - NEW CASES",
      "exam": "Physiotherapy - New Case"
    },
    {
      "count": 26,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Cospicua Health Centre",
      "clinic": "CHC PHYSIOTHERAPY SERVICES",
      "agenda": "CHC PHYSIO TEAM CL 2 - FOLLOW UP",
      "exam": "Physiotherapy - Follow up"
    },
    {
      "count": 78,
      "status": "UNARRIVED",
      "walk-in": null,
      "organization unit": "Cospicua Health Centre",
      "clinic": "CHC PHYSIOTHERAPY SERVICES",
      "agenda": "CHC PHYSIO (M.XUEREB) - NEW CASES",
      "exam": "Physiotherapy - New Case"
    },
    {
      "count": 61,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Cospicua Health Centre",
      "clinic": "CHC PODIATRY SERVICES",
      "agenda": "CHC PODIATRY CORE CLINIC 1",
      "exam": "Podiatry service"
    },
    {
      "count": 86,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Cospicua Health Centre",
      "clinic": "CHC PODIATRY SERVICES",
      "agenda": "CHC PODIATRY CORE CLINIC 1",
      "exam": "Podiatry service"
    },
    {
      "count": 86,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Cospicua Health Centre",
      "clinic": "CHC SOCIAL WORKER",
      "agenda": "CHC SOCIAL WORKER INTERVENTION",
      "exam": "Referral to social worker"
    },
    {
      "count": 88,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Cospicua Health Centre",
      "clinic": "CHC SOCIAL WORKER",
      "agenda": "CHC SOCIAL WORKER INTERVENTION",
      "exam": "Referral to social worker"
    },
    {
      "count": 29,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Cospicua Health Centre",
      "clinic": "CHC SPEECH LANGUAGE SERVICES",
      "agenda": "CHC SPEECH LANG.(MS C SPITERI)",
      "exam": "Speech and language therapy service"
    },
    {
      "count": 8,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Cospicua Health Centre",
      "clinic": "CHC TREATMENT ROOM",
      "agenda": "CHC 768 TREATMENT CLINIC",
      "exam": "Assessment of condition requiring immediate care"
    },
    {
      "count": 60,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Cospicua Health Centre",
      "clinic": "CHC TREATMENT ROOM",
      "agenda": "CHC 301 BLOOD INVESTIGATIONS",
      "exam": "Venous blood sampling"
    },
    {
      "count": 19,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Cospicua Health Centre",
      "clinic": "CHC TREATMENT ROOM",
      "agenda": "CHC 699 ACC CLINIC",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 97,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Cospicua Health Centre",
      "clinic": "CHC TREATMENT ROOM",
      "agenda": "CHC 302 EAR SYRINGING CLINIC",
      "exam": "Syringing of ear"
    },
    {
      "count": 93,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Cospicua Health Centre",
      "clinic": "CHC TREATMENT ROOM",
      "agenda": "CHC 301 BLOOD INVESTIGATIONS",
      "exam": "Venous blood sampling"
    },
    {
      "count": 2,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Cospicua Health Centre",
      "clinic": "CHC TREATMENT ROOM",
      "agenda": "CHC 301 BLOOD INVESTIGATIONS",
      "exam": "Venous blood sampling"
    },
    {
      "count": 53,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Cospicua Health Centre",
      "clinic": "CHC TREATMENT ROOM",
      "agenda": "CHC 699 ACC CLINIC",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 17,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Cospicua Health Centre",
      "clinic": "CHC TREATMENT ROOM",
      "agenda": "CHC 302 EAR SYRINGING CLINIC",
      "exam": "Syringing of ear"
    },
    {
      "count": 87,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Cospicua Health Centre",
      "clinic": "CHC TREATMENT ROOM",
      "agenda": "CHC 301 BLOOD INVESTIGATIONS",
      "exam": "Venous blood sampling"
    },
    {
      "count": 32,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Cospicua Health Centre",
      "clinic": "CHC WOUND MANAGEMENT CLINIC",
      "agenda": "CHC 1075 CHANGE OF DRESSING CLINIC",
      "exam": "Change of dressing"
    },
    {
      "count": 38,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Cospicua Health Centre",
      "clinic": "CHC WOUND MANAGEMENT CLINIC",
      "agenda": "CHC 1075 CHANGE OF DRESSING CLINIC",
      "exam": "Change of dressing"
    },
    {
      "count": 43,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Cospicua Health Centre",
      "clinic": "CHC WOUND MANAGEMENT CLINIC",
      "agenda": "CHC 1075 CHANGE OF DRESSING CLINIC",
      "exam": "Change of dressing"
    },
    {
      "count": 84,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Cospicua Health Centre",
      "clinic": "CHC WOUND MANAGEMENT CLINIC",
      "agenda": "CHC 1075 CHANGE OF DRESSING CLINIC",
      "exam": "Change of dressing"
    },
    {
      "count": 29,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "COMMCARE",
      "clinic": "COMMCARE COVID-19 VRS",
      "agenda": "COMMCARE COVID VRS LIST",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 48,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Dingli Community Clinic",
      "clinic": "DINGLI COVID-19 VRS",
      "agenda": "DINGLI COVID VRS LIST",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 2,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "SCSA - Elderly Homes",
      "clinic": "ELDERLY HOMES COVID-19 VRS",
      "agenda": "ELDERLY HOMES COVID VRS LIST",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 33,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Fgura Community Clinic",
      "clinic": "FGURA COVID-19 VRS",
      "agenda": "FGURA COVID VRS LIST",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 6,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Fgura Community Clinic",
      "clinic": "FGURA PODIATRY SERVICES",
      "agenda": "FGURA PODIATRY CORE CLINIC",
      "exam": "Podiatry service"
    },
    {
      "count": 15,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC ANTICOAGULATION CLINIC",
      "agenda": "FHC INR POC",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 17,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC ANTICOAGULATION CLINIC",
      "agenda": "FHC INR POC",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 67,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC COVID-19 IMMUNISATION SERVICE",
      "agenda": "FHC COVID IMMUNISATION",
      "exam": "COVID-19 vaccination"
    },
    {
      "count": 51,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC COVID-19 IMMUNISATION SERVICE",
      "agenda": "FHC ASTRAZENECA COVID IMMUNISATION",
      "exam": "COVID-19 vaccination"
    },
    {
      "count": 61,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC COVID-19 IMMUNISATION SERVICE",
      "agenda": "FHC COVID IMMUNISATION",
      "exam": "COVID-19 vaccination"
    },
    {
      "count": 72,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC COVID-19 IMMUNISATION SERVICE",
      "agenda": "FHC ASTRAZENECA COVID IMMUNISATION",
      "exam": "COVID-19 vaccination"
    },
    {
      "count": 37,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC COVID-19 IMMUNISATION SERVICE",
      "agenda": "FHC ASTRAZENECA COVID IMMUNISATION",
      "exam": "COVID-19 vaccination"
    },
    {
      "count": 81,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC COVID-19 VRS",
      "agenda": "FHC COVID VRS LIST",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 99,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC DIABETES CLINIC",
      "agenda": "FHC DIABETIC GENERAL",
      "exam": "Diabetes mellitus service"
    },
    {
      "count": 21,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC DIABETES CLINIC",
      "agenda": "FHC DIABETIC GENERAL",
      "exam": "Diabetes mellitus service"
    },
    {
      "count": 11,
      "status": "IN_PROGRESS",
      "walk-in": null,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC DIABETES CLINIC",
      "agenda": "FHC DIABETIC GENERAL",
      "exam": "Diabetes mellitus service"
    },
    {
      "count": 89,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC DIABETES CLINIC",
      "agenda": "FHC DIABETIC GENERAL",
      "exam": "Diabetes mellitus service"
    },
    {
      "count": 32,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC GP CLINIC",
      "agenda": "FHC 759 GP CLINIC",
      "exam": "Encounter for problem"
    },
    {
      "count": 10,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC GP CLINIC",
      "agenda": "FHC 759 GP CLINIC",
      "exam": "Telephone consultation"
    },
    {
      "count": 42,
      "status": "FINISHED",
      "walk-in": true,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC GP CLINIC",
      "agenda": "FHC 759 GP CLINIC",
      "exam": "Encounter for problem"
    },
    {
      "count": 86,
      "status": "FINISHED",
      "walk-in": true,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC GP CLINIC",
      "agenda": "FHC 759 GP CLINIC",
      "exam": "Telephone consultation"
    },
    {
      "count": 84,
      "status": "IN_PROGRESS",
      "walk-in": true,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC GP CLINIC",
      "agenda": "FHC 759 GP CLINIC",
      "exam": "Encounter for problem"
    },
    {
      "count": 69,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC OPHTHALMIC SERVICES",
      "agenda": "FHC OPHTH - MR IVAN VELLA",
      "exam": "Refraction assessment"
    },
    {
      "count": 30,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC OPHTHALMIC SERVICES",
      "agenda": "PHC OPH DIAB. RETINOPATHY SCREENING",
      "exam": "Diabetic retinopathy screening"
    },
    {
      "count": 91,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC OPHTHALMIC SERVICES",
      "agenda": "PHC OPH DIAB. RETINOPATHY SCREENING",
      "exam": "Diabetic retinopathy screening"
    },
    {
      "count": 8,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC OPHTHALMIC SERVICES",
      "agenda": "FHC GLAUCOMA.CL.(MONDAY NURSE)",
      "exam": "Glaucoma screening"
    },
    {
      "count": 78,
      "status": "IN_PROGRESS",
      "walk-in": null,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC OPHTHALMIC SERVICES",
      "agenda": "PHC OPH DIAB. RETINOPATHY SCREENING",
      "exam": "Diabetic retinopathy screening"
    },
    {
      "count": 95,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC OPHTHALMIC SERVICES",
      "agenda": "PHC OPH DIAB. RETINOPATHY SCREENING",
      "exam": "Diabetic retinopathy screening"
    },
    {
      "count": 57,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC OPHTHALMIC SERVICES",
      "agenda": "FHC GLAUCOMA.CL.(MONDAY NURSE)",
      "exam": "Glaucoma screening"
    },
    {
      "count": 66,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC OPHTHALMIC SERVICES",
      "agenda": "FHC OPHTH - MR IVAN VELLA",
      "exam": "Refraction assessment"
    },
    {
      "count": 86,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC PHYSIOTHERAPY SERVICES",
      "agenda": "FHC PHYSIO ROTATION 1 - NEW CASES",
      "exam": "Physiotherapy - New Case"
    },
    {
      "count": 56,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC PHYSIOTHERAPY SERVICES",
      "agenda": "FHC PHYSIO ROTATION 1 - NEW CASES",
      "exam": "Physiotherapy - New Case"
    },
    {
      "count": 23,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC PHYSIOTHERAPY SERVICES",
      "agenda": "FHC PHYSIO ROTATION 2  - NEW CASES",
      "exam": "Physiotherapy - New Case"
    },
    {
      "count": 22,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC PHYSIOTHERAPY SERVICES",
      "agenda": "FHC PHYSIO ROTATION 2 - FOLLOW-UP",
      "exam": "Physiotherapy - Follow up"
    },
    {
      "count": 24,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC PHYSIOTHERAPY SERVICES",
      "agenda": "FHC SEN PHYSIO ROTATION 1 - FOLLOW-UP",
      "exam": "Physiotherapy - Follow up"
    },
    {
      "count": 67,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC PHYSIOTHERAPY SERVICES",
      "agenda": "FHC PHYSIO ROTATION 1 - NEW CASES",
      "exam": "Physiotherapy - New Case"
    },
    {
      "count": 100,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC PHYSIOTHERAPY SERVICES",
      "agenda": "FHC PHYSIO ROTATION 2  - NEW CASES",
      "exam": "Physiotherapy - New Case"
    },
    {
      "count": 17,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC PHYSIOTHERAPY SERVICES",
      "agenda": "FHC PHYSIO ROTATION 2  - NEW CASES",
      "exam": "Physiotherapy - New Case"
    },
    {
      "count": 35,
      "status": "IN_PROGRESS",
      "walk-in": null,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC PHYSIOTHERAPY SERVICES",
      "agenda": "FHC PHYSIO ROTATION 2 - FOLLOW-UP",
      "exam": "Physiotherapy - Follow up"
    },
    {
      "count": 54,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC PODIATRY SERVICES",
      "agenda": "FHC PODIATRY CORE CLINIC 3",
      "exam": "Podiatry service"
    },
    {
      "count": 64,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC PODIATRY SERVICES",
      "agenda": "FHC PODIATRY CORE CLINIC 3",
      "exam": "Podiatry service"
    },
    {
      "count": 24,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC PODIATRY SERVICES",
      "agenda": "FHC PODIATRY CORE CLINIC 1",
      "exam": "Podiatry service"
    },
    {
      "count": 97,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC PODIATRY SERVICES",
      "agenda": "FHC PODIATRY CORE CLINIC 1",
      "exam": "Podiatry service"
    },
    {
      "count": 49,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC PODIATRY SERVICES",
      "agenda": "FHC PODIATRY CORE CLINIC 2",
      "exam": "Podiatry service"
    },
    {
      "count": 41,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC PODIATRY SERVICES",
      "agenda": "FHC PODIATRY CORE CLINIC 1",
      "exam": "Podiatry service"
    },
    {
      "count": 24,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC PODIATRY SERVICES",
      "agenda": "FHC PODIATRY CORE CLINIC 2",
      "exam": "Podiatry service"
    },
    {
      "count": 52,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC PODIATRY SERVICES",
      "agenda": "FHC PODIATRY CORE CLINIC 3",
      "exam": "Podiatry service"
    },
    {
      "count": 33,
      "status": "FINISHED",
      "walk-in": true,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC SPEECH LANGUAGE SERVICES",
      "agenda": "FHC SPEECH LANG.MILIANA PACE",
      "exam": "Speech and language therapy service"
    },
    {
      "count": 4,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC SPEECH LANGUAGE SERVICES",
      "agenda": "FHC SPEECH LANG.MILIANA PACE",
      "exam": "Speech and language therapy service"
    },
    {
      "count": 13,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC SPEECH LANGUAGE SERVICES",
      "agenda": "FHC SPEECH LANG.MILIANA PACE",
      "exam": "Speech and language therapy service"
    },
    {
      "count": 8,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC TREATMENT ROOM",
      "agenda": "FHC 734 ACC CLINIC",
      "exam": "Anticoagulant control test"
    },
    {
      "count": 2,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC TREATMENT ROOM",
      "agenda": "FHC 758 TREATMENT CLINIC",
      "exam": "Assessment of condition requiring immediate care"
    },
    {
      "count": 70,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC TREATMENT ROOM",
      "agenda": "FHC 758 TREATMENT CLINIC",
      "exam": "Change of dressing"
    },
    {
      "count": 63,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC TREATMENT ROOM",
      "agenda": "FHC 57 BLOOD INVESTIGATIONS",
      "exam": "Venous blood sampling"
    },
    {
      "count": 68,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC TREATMENT ROOM",
      "agenda": "FHC 734 ACC CLINIC",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 8,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC TREATMENT ROOM",
      "agenda": "FHC 60 EAR SYRINGING",
      "exam": "Syringing of ear"
    },
    {
      "count": 52,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC TREATMENT ROOM",
      "agenda": "FHC 57 BLOOD INVESTIGATIONS",
      "exam": "Venous blood sampling"
    },
    {
      "count": 77,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC TREATMENT ROOM",
      "agenda": "FHC 57 BLOOD INVESTIGATIONS",
      "exam": "Venous blood sampling"
    },
    {
      "count": 6,
      "status": "IN_PROGRESS",
      "walk-in": null,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC TREATMENT ROOM",
      "agenda": "FHC 57 BLOOD INVESTIGATIONS",
      "exam": "Venous blood sampling"
    },
    {
      "count": 49,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC TREATMENT ROOM",
      "agenda": "FHC 734 ACC CLINIC",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 83,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC TREATMENT ROOM",
      "agenda": "FHC 57 BLOOD INVESTIGATIONS",
      "exam": "Venous blood sampling"
    },
    {
      "count": 88,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC WOUND MANAGEMENT CLINIC",
      "agenda": "FHC 1037 WOUND CLINIC",
      "exam": "Change of dressing"
    },
    {
      "count": 96,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC WOUND MANAGEMENT CLINIC",
      "agenda": "FHC 1037 WOUND CLINIC",
      "exam": "Change of dressing"
    },
    {
      "count": 32,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC WOUND MANAGEMENT CLINIC",
      "agenda": "FHC 1037 WOUND CLINIC",
      "exam": "Change of dressing"
    },
    {
      "count": 40,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC WOUND MANAGEMENT CLINIC",
      "agenda": "FHC 1037 WOUND CLINIC",
      "exam": "Change of dressing"
    },
    {
      "count": 68,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Floriana Health Centre",
      "clinic": "FHC X-RAY ROOM",
      "agenda": "FHC X-RAYS CL. RADIOLOGY",
      "exam": "To take X-Ray"
    },
    {
      "count": 9,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Mtarfa Primary HealthCare Clinic",
      "clinic": "GENDER CLINIC",
      "agenda": "RHC MTARFA DR.M.CACHIA GWB ENDO",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 81,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Mtarfa Primary HealthCare Clinic",
      "clinic": "GENDER CLINIC",
      "agenda": "RHC MTARFA DR.M.CACHIA GWB ENDO",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 84,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Ghaxaq Community Clinic",
      "clinic": "GHAXAQ COVID-19 VRS",
      "agenda": "GHAXAQ COVID VRS LIST",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 53,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Ghaxaq Community Clinic",
      "clinic": "GHAXAQ NURSING SERVICES",
      "agenda": "GHAXAQ NURSE LED CLINIC",
      "exam": "Telephone consultation"
    },
    {
      "count": 92,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Gzira Health Centre",
      "clinic": "GHC ANTICOAGULATION CLINIC",
      "agenda": "GHC INR POC",
      "exam": "Anticoagulant control"
    },
    {
      "count": 67,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Gzira Health Centre",
      "clinic": "GHC ANTICOAGULATION CLINIC",
      "agenda": "GHC INR POC",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 40,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Gzira Health Centre",
      "clinic": "GHC ANTICOAGULATION CLINIC",
      "agenda": "GHC INR POC",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 43,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Gzira Health Centre",
      "clinic": "GHC COVID-19 IMMUNISATION SERVICE",
      "agenda": "GHC ASTRAZENECA COVID IMMUNISATION",
      "exam": "COVID-19 vaccination"
    },
    {
      "count": 10,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Gzira Health Centre",
      "clinic": "GHC COVID-19 IMMUNISATION SERVICE",
      "agenda": "GHC COVID IMMUNISATION",
      "exam": "COVID-19 vaccination"
    },
    {
      "count": 89,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Gzira Health Centre",
      "clinic": "GHC COVID-19 IMMUNISATION SERVICE",
      "agenda": "GHC ASTRAZENECA COVID IMMUNISATION",
      "exam": "COVID-19 vaccination"
    },
    {
      "count": 55,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Gzira Health Centre",
      "clinic": "GHC COVID-19 IMMUNISATION SERVICE",
      "agenda": "GHC COVID IMMUNISATION",
      "exam": "COVID-19 vaccination"
    },
    {
      "count": 11,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Gzira Health Centre",
      "clinic": "GHC COVID-19 VRS",
      "agenda": "GHC COVID VRS LIST",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 37,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Gzira Health Centre",
      "clinic": "GHC DIABETES CLINIC",
      "agenda": "GHC DIABETIC GENERAL",
      "exam": "Diabetes mellitus service"
    },
    {
      "count": 3,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Gzira Health Centre",
      "clinic": "GHC DIABETES CLINIC",
      "agenda": "GHC DIABETIC GENERAL",
      "exam": "Diabetes mellitus service"
    },
    {
      "count": 14,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Gzira Health Centre",
      "clinic": "GHC DIABETES CLINIC",
      "agenda": "GHC DIABETIC GENERAL",
      "exam": "Diabetes mellitus service"
    },
    {
      "count": 32,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Gzira Health Centre",
      "clinic": "GHC GP CLINIC",
      "agenda": "GHC 763 GP CLINIC",
      "exam": "Encounter for problem"
    },
    {
      "count": 27,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Gzira Health Centre",
      "clinic": "GHC GP CLINIC",
      "agenda": "GHC 763 GP CLINIC",
      "exam": "Telephone consultation"
    },
    {
      "count": 21,
      "status": "FINISHED",
      "walk-in": true,
      "organization unit": "Gzira Health Centre",
      "clinic": "GHC GP CLINIC",
      "agenda": "GHC 763 GP CLINIC",
      "exam": "Encounter for problem"
    },
    {
      "count": 80,
      "status": "FINISHED",
      "walk-in": true,
      "organization unit": "Gzira Health Centre",
      "clinic": "GHC GP CLINIC",
      "agenda": "GHC 763 GP CLINIC",
      "exam": "Telephone consultation"
    },
    {
      "count": 66,
      "status": "IN_PROGRESS",
      "walk-in": true,
      "organization unit": "Gzira Health Centre",
      "clinic": "GHC GP CLINIC",
      "agenda": "GHC 763 GP CLINIC",
      "exam": "Encounter for problem"
    },
    {
      "count": 50,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Gzira Health Centre",
      "clinic": "GHC TREATMENT ROOM",
      "agenda": "GHC 185 BLOOD INVESTIGATIONS",
      "exam": "Venous blood sampling"
    },
    {
      "count": 68,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Gzira Health Centre",
      "clinic": "GHC TREATMENT ROOM",
      "agenda": "GHC 700 ACC CLINIC",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 84,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Gzira Health Centre",
      "clinic": "GHC TREATMENT ROOM",
      "agenda": "GHC 185 BLOOD INVESTIGATIONS",
      "exam": "Venous blood sampling"
    },
    {
      "count": 10,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Gzira Health Centre",
      "clinic": "GHC TREATMENT ROOM",
      "agenda": "GHC 185 BLOOD INVESTIGATIONS",
      "exam": "Venous blood sampling"
    },
    {
      "count": 1,
      "status": "FINISHED",
      "walk-in": true,
      "organization unit": "Gzira Health Centre",
      "clinic": "GHC TREATMENT ROOM",
      "agenda": "GHC 762 TREATMENT CLINIC",
      "exam": "Assessment of condition requiring immediate care"
    },
    {
      "count": 50,
      "status": "IN_PROGRESS",
      "walk-in": null,
      "organization unit": "Gzira Health Centre",
      "clinic": "GHC TREATMENT ROOM",
      "agenda": "GHC 185 BLOOD INVESTIGATIONS",
      "exam": "Venous blood sampling"
    },
    {
      "count": 90,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Gzira Health Centre",
      "clinic": "GHC TREATMENT ROOM",
      "agenda": "GHC 185 BLOOD INVESTIGATIONS",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 60,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Gzira Health Centre",
      "clinic": "GHC TREATMENT ROOM",
      "agenda": "GHC 700 ACC CLINIC",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 92,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Gzira Health Centre",
      "clinic": "GHC TREATMENT ROOM",
      "agenda": "GHC 185 BLOOD INVESTIGATIONS",
      "exam": "Venous blood sampling"
    },
    {
      "count": 37,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Gzira Health Centre",
      "clinic": "GHC WOMAN'S HEALTH CLINIC",
      "agenda": "GHC OBS. GYN CL.",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 6,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Gzira Health Centre",
      "clinic": "GHC WOMAN'S HEALTH CLINIC",
      "agenda": "GH2135 GHC CERVICAL SCREENING PR.",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 41,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Gzira Health Centre",
      "clinic": "GHC WOMAN'S HEALTH CLINIC",
      "agenda": "GHC OBS. ANTEN.",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 31,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Gzira Health Centre",
      "clinic": "GHC WOMAN'S HEALTH CLINIC",
      "agenda": "GHC OBS. GYN CL.",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 22,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Gzira Health Centre",
      "clinic": "GHC WOMAN'S HEALTH CLINIC",
      "agenda": "GHC OBS. POST NAT.",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 68,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Gzira Health Centre",
      "clinic": "GHC WOUND MANAGEMENT CLINIC",
      "agenda": "GHC 1267 CHANGE OF DRESSING CLINIC",
      "exam": "Change of dressing"
    },
    {
      "count": 25,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Gzira Health Centre",
      "clinic": "GHC WOUND MANAGEMENT CLINIC",
      "agenda": "GHC 1267 CHANGE OF DRESSING CLINIC",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 37,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Gzira Health Centre",
      "clinic": "GHC WOUND MANAGEMENT CLINIC",
      "agenda": "GHC 1267 CHANGE OF DRESSING CLINIC",
      "exam": "Change of dressing"
    },
    {
      "count": 97,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Gozo Conference & Expo Centre",
      "clinic": "GOZO CONF & EXPO COVID-19 VRS",
      "agenda": "GOZO EXPO COVID VRS LIST",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 43,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Hamrun Community Clinic",
      "clinic": "HAMRUN COVID-19 VRS",
      "agenda": "HAMRUN COVID VRS LIST",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 80,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Kercem Community Clinic",
      "clinic": "KERCEM COVID-19 IMMUNISATION SERVICE",
      "agenda": "KERCEM COVID IMMUNISATION",
      "exam": "COVID-19 vaccination"
    },
    {
      "count": 70,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Kercem Community Clinic",
      "clinic": "KERCEM COVID-19 IMMUNISATION SERVICE",
      "agenda": "KERCEM ASTRAZENECA COVID IMMUNISATION",
      "exam": "COVID-19 vaccination"
    },
    {
      "count": 10,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Kercem Community Clinic",
      "clinic": "KERCEM COVID-19 IMMUNISATION SERVICE",
      "agenda": "KERCEM COVID IMMUNISATION",
      "exam": "COVID-19 vaccination"
    },
    {
      "count": 77,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Karin Grech Hospital",
      "clinic": "KGH COVID-19 VRS",
      "agenda": "KGH COVID VRS LIST",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 81,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Kirkop Health Centre",
      "clinic": "KHC COVID-19 IMMUNISATION SERVICE",
      "agenda": "KHC ASTRAZENECA COVID IMMUNISATION",
      "exam": "COVID-19 vaccination"
    },
    {
      "count": 50,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Kirkop Health Centre",
      "clinic": "KHC COVID-19 IMMUNISATION SERVICE",
      "agenda": "KHC ASTRAZENECA COVID IMMUNISATION",
      "exam": "COVID-19 vaccination"
    },
    {
      "count": 90,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Kirkop Health Centre",
      "clinic": "KHC COVID-19 IMMUNISATION SERVICE",
      "agenda": "KHC ASTRAZENECA COVID IMMUNISATION",
      "exam": "COVID-19 vaccination"
    },
    {
      "count": 17,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Kirkop Health Centre",
      "clinic": "KHC DIABETES CLINIC",
      "agenda": "KHC DIABETIC CLINIC 3 GEN",
      "exam": "Diabetes mellitus service"
    },
    {
      "count": 9,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Kirkop Health Centre",
      "clinic": "KHC DIABETES CLINIC",
      "agenda": "KHC DIABETIC CLINIC 3 GEN",
      "exam": "Diabetes mellitus service"
    },
    {
      "count": 35,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Kirkop Health Centre",
      "clinic": "KHC DIABETES CLINIC",
      "agenda": "KHC DIABETIC CLINIC 3 GEN",
      "exam": "Diabetes mellitus service"
    },
    {
      "count": 57,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Kirkop Health Centre",
      "clinic": "KHC DIETETICS SERVICES",
      "agenda": "KHC MS.D.BUTTIGIEG - FU",
      "exam": "Dietetic procedures"
    },
    {
      "count": 13,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Kirkop Health Centre",
      "clinic": "KHC DIETETICS SERVICES",
      "agenda": "KHC MS.D.BUTTIGIEG - NEW",
      "exam": "Dietetic procedures"
    },
    {
      "count": 88,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Kirkop Health Centre",
      "clinic": "KHC DIETETICS SERVICES",
      "agenda": "KHC MS.D.BUTTIGIEG - NEW",
      "exam": "Dietetic procedures"
    },
    {
      "count": 59,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Kirkop Health Centre",
      "clinic": "KHC GP CLINIC",
      "agenda": "KHFAMHCGPGPC",
      "exam": "Encounter for problem"
    },
    {
      "count": 59,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Kirkop Health Centre",
      "clinic": "KHC GP CLINIC",
      "agenda": "KHFAMHCGPGPC",
      "exam": "Telephone consultation"
    },
    {
      "count": 57,
      "status": "FINISHED",
      "walk-in": true,
      "organization unit": "Kirkop Health Centre",
      "clinic": "KHC GP CLINIC",
      "agenda": "KHFAMHCGPGPC",
      "exam": "Encounter for problem"
    },
    {
      "count": 53,
      "status": "FINISHED",
      "walk-in": true,
      "organization unit": "Kirkop Health Centre",
      "clinic": "KHC GP CLINIC",
      "agenda": "KHFAMHCGPGPC",
      "exam": "Telephone consultation"
    },
    {
      "count": 59,
      "status": "IN_PROGRESS",
      "walk-in": true,
      "organization unit": "Kirkop Health Centre",
      "clinic": "KHC GP CLINIC",
      "agenda": "KHFAMHCGPGPC",
      "exam": "Encounter for problem"
    },
    {
      "count": 65,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Kirkop Health Centre",
      "clinic": "KHC PHYSIOTHERAPY  SERVICES",
      "agenda": "KHC PHYSIO CL 5 - FOLLOW UP",
      "exam": "Physiotherapy - Follow up"
    },
    {
      "count": 46,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Kirkop Health Centre",
      "clinic": "KHC PHYSIOTHERAPY  SERVICES",
      "agenda": "KHC PHYSIO CL 5 - FOLLOW UP",
      "exam": "Physiotherapy - Follow up"
    },
    {
      "count": 42,
      "status": "FINISHED",
      "walk-in": true,
      "organization unit": "Kirkop Health Centre",
      "clinic": "KHC PHYSIOTHERAPY  SERVICES",
      "agenda": "KHC PHYSIO CL 5 - FOLLOW UP",
      "exam": "Physiotherapy - Follow up"
    },
    {
      "count": 76,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Kirkop Health Centre",
      "clinic": "KHC PHYSIOTHERAPY  SERVICES",
      "agenda": "KHC PHYSIO CL 5 - FOLLOW UP",
      "exam": "Physiotherapy - Follow up"
    },
    {
      "count": 77,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Kirkop Health Centre",
      "clinic": "KHC PHYSIOTHERAPY  SERVICES",
      "agenda": "KHC PHYSIO LUKE B. - FOLLOW UP",
      "exam": "Physiotherapy - Follow up"
    },
    {
      "count": 70,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Kirkop Health Centre",
      "clinic": "KHC PHYSIOTHERAPY  SERVICES",
      "agenda": "KHC PHYSIO CL 5 - NEW CASES",
      "exam": "Physiotherapy - New Case"
    },
    {
      "count": 75,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Kirkop Health Centre",
      "clinic": "KHC PHYSIOTHERAPY  SERVICES",
      "agenda": "KHC PHYSIO LUKE B - NEW CASES",
      "exam": "Physiotherapy - New Case"
    },
    {
      "count": 6,
      "status": "IN_PROGRESS",
      "walk-in": null,
      "organization unit": "Kirkop Health Centre",
      "clinic": "KHC PHYSIOTHERAPY  SERVICES",
      "agenda": "KHC PHYSIO CL 5 - NEW CASES",
      "exam": "Physiotherapy - New Case"
    },
    {
      "count": 26,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Kirkop Health Centre",
      "clinic": "KHC PHYSIOTHERAPY  SERVICES",
      "agenda": "KHC PHYSIO CL 5 - FOLLOW UP",
      "exam": "Physiotherapy - Follow up"
    },
    {
      "count": 18,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Kirkop Health Centre",
      "clinic": "KHC PHYSIOTHERAPY  SERVICES",
      "agenda": "KHC PHYSIO LUKE B. - FOLLOW UP",
      "exam": "Physiotherapy - Follow up"
    },
    {
      "count": 76,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Kirkop Health Centre",
      "clinic": "KHC PHYSIOTHERAPY  SERVICES",
      "agenda": "KHC PHYSIO LUKE B - NEW CASES",
      "exam": "Physiotherapy - New Case"
    },
    {
      "count": 9,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Kirkop Health Centre",
      "clinic": "KHC PODIATRY SERVICES",
      "agenda": "KHC PODIATRY CORE CLINIC",
      "exam": "Podiatry service"
    },
    {
      "count": 85,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Kirkop Health Centre",
      "clinic": "KHC PODIATRY SERVICES",
      "agenda": "KHC PODIATRY CORE CLINIC",
      "exam": "Podiatry service"
    },
    {
      "count": 46,
      "status": "IN_PROGRESS",
      "walk-in": null,
      "organization unit": "Kirkop Health Centre",
      "clinic": "KHC PODIATRY SERVICES",
      "agenda": "KHC PODIATRY CORE CLINIC",
      "exam": "Podiatry service"
    },
    {
      "count": 88,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Kirkop Health Centre",
      "clinic": "KHC PODIATRY SERVICES",
      "agenda": "KHC PODIATRY CORE CLINIC",
      "exam": "Podiatry service"
    },
    {
      "count": 47,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Kirkop Health Centre",
      "clinic": "KHC SPEECH LANGUAGE SERVICES",
      "agenda": "KHC SPEECH LANG. CL1",
      "exam": "Speech and language therapy service"
    },
    {
      "count": 50,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Kirkop Health Centre",
      "clinic": "KHC TREATMENT ROOM",
      "agenda": "KHC BLOOD LETTING SERVICE",
      "exam": "Venous blood sampling"
    },
    {
      "count": 85,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Kirkop Health Centre",
      "clinic": "KHC TREATMENT ROOM",
      "agenda": "KHC ACC CLINIC",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 81,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Kirkop Health Centre",
      "clinic": "KHC TREATMENT ROOM",
      "agenda": "KHC EAR SYRINGING",
      "exam": "Syringing of ear"
    },
    {
      "count": 57,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Kirkop Health Centre",
      "clinic": "KHC TREATMENT ROOM",
      "agenda": "KHC BLOOD LETTING SERVICE",
      "exam": "Venous blood sampling"
    },
    {
      "count": 32,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Kirkop Health Centre",
      "clinic": "KHC TREATMENT ROOM",
      "agenda": "KHC BLOOD LETTING SERVICE",
      "exam": "Venous blood sampling"
    },
    {
      "count": 21,
      "status": "FINISHED",
      "walk-in": true,
      "organization unit": "Kirkop Health Centre",
      "clinic": "KHC TREATMENT ROOM",
      "agenda": "KHC RX ROOM",
      "exam": "Assessment of condition requiring immediate care"
    },
    {
      "count": 27,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Kirkop Health Centre",
      "clinic": "KHC TREATMENT ROOM",
      "agenda": "KHC EAR SYRINGING",
      "exam": "Syringing of ear"
    },
    {
      "count": 5,
      "status": "IN_PROGRESS",
      "walk-in": true,
      "organization unit": "Kirkop Health Centre",
      "clinic": "KHC TREATMENT ROOM",
      "agenda": "KHC RX ROOM",
      "exam": "Assessment of condition requiring immediate care"
    },
    {
      "count": 61,
      "status": "IN_PROGRESS",
      "walk-in": true,
      "organization unit": "Kirkop Health Centre",
      "clinic": "KHC TREATMENT ROOM",
      "agenda": "KHC RX ROOM",
      "exam": "Removal of skin sutures/clips"
    },
    {
      "count": 68,
      "status": "IN_PROGRESS",
      "walk-in": null,
      "organization unit": "Kirkop Health Centre",
      "clinic": "KHC TREATMENT ROOM",
      "agenda": "KHC EAR SYRINGING",
      "exam": "Syringing of ear"
    },
    {
      "count": 3,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Kirkop Health Centre",
      "clinic": "KHC TREATMENT ROOM",
      "agenda": "KHC ACC CLINIC",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 61,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Kirkop Health Centre",
      "clinic": "KHC TREATMENT ROOM",
      "agenda": "KHC BLOOD LETTING SERVICE",
      "exam": "Venous blood sampling"
    },
    {
      "count": 39,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Kirkop Health Centre",
      "clinic": "KHC WOUND MANAGEMENT CLINIC",
      "agenda": "KHC WOUND CLINIC COD",
      "exam": "Change of dressing"
    },
    {
      "count": 26,
      "status": "FINISHED",
      "walk-in": true,
      "organization unit": "Kirkop Health Centre",
      "clinic": "KHC WOUND MANAGEMENT CLINIC",
      "agenda": "KHC WOUND CLINIC COD",
      "exam": "Change of dressing"
    },
    {
      "count": 49,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Centru LEAP (Qawra)",
      "clinic": "LEAP (QAWRA) COVID-19 VRS",
      "agenda": "LEAP (QAWRA) COVID VRS LIST",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 58,
      "status": "FINISHED",
      "walk-in": true,
      "organization unit": "AWAS",
      "clinic": "Marsa  AWAS Centre",
      "agenda": "MARSA AWAS CENTRE",
      "exam": "Encounter for problem"
    },
    {
      "count": 49,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Malta College of Arts, Science & Technology",
      "clinic": "MCAST(PAOLA) COVID-19 VRS",
      "agenda": "MCAST(PAOLA) COVID VRS LIST",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 76,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Mount Carmel Hospital",
      "clinic": "MCH COVID-19 VRS",
      "agenda": "MCH COVID VRS LIST",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 16,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Mellieha Community Clinic",
      "clinic": "MELLIEHA COVID-19 VRS",
      "agenda": "MELLIEHA COVID VRS LIST",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 32,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC ANTICOAGULATION CLINIC",
      "agenda": "MHC ANTICOAGULANT CLINIC",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 66,
      "status": "IN_PROGRESS",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC ANTICOAGULATION CLINIC",
      "agenda": "MHC ANTICOAGULANT CLINIC",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 64,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC ANTICOAGULATION CLINIC",
      "agenda": "MHC ANTICOAGULANT CLINIC",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 6,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC CARDIOLOGY CLINIC",
      "agenda": "MHC DR H.FELICE - FOLLOW-UP",
      "exam": "Cardiology service"
    },
    {
      "count": 23,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC CARDIOLOGY CLINIC",
      "agenda": "MHC DR H.FELICE - FOLLOW-UP",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 45,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC CARDIOLOGY CLINIC",
      "agenda": "MHC DR H.FELICE - FOLLOW-UP",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 8,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC COVID-19 IMMUNISATION SERVICE",
      "agenda": "MHC ASTRAZENECA COVID IMMUNISATION",
      "exam": "COVID-19 vaccination"
    },
    {
      "count": 77,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC COVID-19 IMMUNISATION SERVICE",
      "agenda": "MHC ASTRAZENECA COVID IMMUNISATION",
      "exam": "COVID-19 vaccination"
    },
    {
      "count": 92,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC COVID-19 IMMUNISATION SERVICE",
      "agenda": "MHC COVID IMMUNISATION",
      "exam": "COVID-19 vaccination"
    },
    {
      "count": 92,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC COVID-19 IMMUNISATION SERVICE",
      "agenda": "MHC ASTRAZENECA COVID IMMUNISATION",
      "exam": "COVID-19 vaccination"
    },
    {
      "count": 74,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC COVID-19 VRS",
      "agenda": "MHC COVID VRS LIST",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 9,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC DIABETES CLINIC",
      "agenda": "MHC DIABETIC NURSE LED GENERAL",
      "exam": "Diabetes mellitus service"
    },
    {
      "count": 7,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC DIABETES CLINIC",
      "agenda": "MHC DIABETIC NURSE LED GENERAL",
      "exam": "Diabetes mellitus service"
    },
    {
      "count": 87,
      "status": "IN_PROGRESS",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC DIABETES CLINIC",
      "agenda": "MHC DIABETIC NURSE LED GENERAL",
      "exam": "Diabetes mellitus service"
    },
    {
      "count": 44,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC DIABETES CLINIC",
      "agenda": "MHC DIABETIC NURSE LED GENERAL",
      "exam": "Diabetes mellitus service"
    },
    {
      "count": 87,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC GP CLINIC",
      "agenda": "MHC 755 GP CLINIC",
      "exam": "Encounter for problem"
    },
    {
      "count": 5,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC GP CLINIC",
      "agenda": "MHC 755 GP CLINIC",
      "exam": "Telephone consultation"
    },
    {
      "count": 46,
      "status": "FINISHED",
      "walk-in": true,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC GP CLINIC",
      "agenda": "MHC 755 GP CLINIC",
      "exam": "Encounter for problem"
    },
    {
      "count": 75,
      "status": "FINISHED",
      "walk-in": true,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC GP CLINIC",
      "agenda": "MHC 755 GP CLINIC",
      "exam": "Telephone consultation"
    },
    {
      "count": 78,
      "status": "IN_PROGRESS",
      "walk-in": true,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC GP CLINIC",
      "agenda": "MHC 755 GP CLINIC",
      "exam": "Encounter for problem"
    },
    {
      "count": 57,
      "status": "IN_PROGRESS",
      "walk-in": true,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC GP CLINIC",
      "agenda": "MHC 755 GP CLINIC",
      "exam": "Telephone consultation"
    },
    {
      "count": 7,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC OPHTHALMIC SERVICES",
      "agenda": "MHC OPH TRIAGE SESSION",
      "exam": "Ophthalmic examination and evaluation"
    },
    {
      "count": 27,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC OPHTHALMIC SERVICES",
      "agenda": "MHC 38 OPHTHALMIC CLINIC",
      "exam": "Refraction assessment"
    },
    {
      "count": 75,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC OPHTHALMIC SERVICES",
      "agenda": "MHC 38 OPHTHALMIC CLINIC",
      "exam": "Refraction assessment"
    },
    {
      "count": 5,
      "status": "FINISHED",
      "walk-in": true,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC OPHTHALMIC SERVICES",
      "agenda": "MHC OPH TRIAGE SESSION",
      "exam": "Ophthalmic examination and evaluation"
    },
    {
      "count": 29,
      "status": "FINISHED",
      "walk-in": true,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC OPHTHALMIC SERVICES",
      "agenda": "MHC 38 OPHTHALMIC CLINIC",
      "exam": "Refraction assessment"
    },
    {
      "count": 33,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC OPHTHALMIC SERVICES",
      "agenda": "MHC 38 OPHTHALMIC CLINIC",
      "exam": "Refraction assessment"
    },
    {
      "count": 28,
      "status": "IN_PROGRESS",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC OPHTHALMIC SERVICES",
      "agenda": "MHC 38 OPHTHALMIC CLINIC",
      "exam": "Refraction assessment"
    },
    {
      "count": 86,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC OPHTHALMIC SERVICES",
      "agenda": "MHC 38 OPHTHALMIC CLINIC",
      "exam": "Refraction assessment"
    },
    {
      "count": 69,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC PHYSIOTHERAPY SERVICES",
      "agenda": "MHC PHYSIO CL 1 - NEW CASES",
      "exam": "Physiotherapy - New Case"
    },
    {
      "count": 99,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC PHYSIOTHERAPY SERVICES",
      "agenda": "MHC PHYSIO CL 1 - FOLLOW-UP",
      "exam": "Physiotherapy - Follow up"
    },
    {
      "count": 18,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC PHYSIOTHERAPY SERVICES",
      "agenda": "MHC PHYSIO CL 1 - NEW CASES",
      "exam": "Physiotherapy - New Case"
    },
    {
      "count": 97,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC PHYSIOTHERAPY SERVICES",
      "agenda": "MHC PHYSIO CL 2 - NEW CASES",
      "exam": "Physiotherapy - New Case"
    },
    {
      "count": 94,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC PHYSIOTHERAPY SERVICES",
      "agenda": "MHC PHYSIO CL 1 - NEW CASES",
      "exam": "Physiotherapy - New Case"
    },
    {
      "count": 12,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC PODIATRY SERVICES",
      "agenda": "MHC PODIATRY CORE CLINIC 2",
      "exam": "Podiatry service"
    },
    {
      "count": 85,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC PODIATRY SERVICES",
      "agenda": "MHC PODIATRY CORE CLINIC 3",
      "exam": "Podiatry service"
    },
    {
      "count": 18,
      "status": "CANCELLED",
      "walk-in": true,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC PODIATRY SERVICES",
      "agenda": "MHC  PODIATRY CORE CLINIC 1",
      "exam": "Podiatry service"
    },
    {
      "count": 84,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC PODIATRY SERVICES",
      "agenda": "MHC  PODIATRY CORE CLINIC 1",
      "exam": "Podiatry service"
    },
    {
      "count": 74,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC PODIATRY SERVICES",
      "agenda": "MHC PODIATRY CORE CLINIC 2",
      "exam": "Podiatry service"
    },
    {
      "count": 81,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC PODIATRY SERVICES",
      "agenda": "MHC PODIATRY CORE CLINIC 3",
      "exam": "Podiatry service"
    },
    {
      "count": 49,
      "status": "FINISHED",
      "walk-in": true,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC PODIATRY SERVICES",
      "agenda": "MHC  PODIATRY CORE CLINIC 1",
      "exam": "Podiatry service"
    },
    {
      "count": 94,
      "status": "FINISHED",
      "walk-in": true,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC PODIATRY SERVICES",
      "agenda": "MHC PODIATRY CORE CLINIC 3",
      "exam": "Podiatry service"
    },
    {
      "count": 50,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC PODIATRY SERVICES",
      "agenda": "MHC  PODIATRY CORE CLINIC 1",
      "exam": "Podiatry service"
    },
    {
      "count": 57,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC PODIATRY SERVICES",
      "agenda": "MHC PODIATRY CORE CLINIC 2",
      "exam": "Podiatry service"
    },
    {
      "count": 76,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC PODIATRY SERVICES",
      "agenda": "MHC PODIATRY CORE CLINIC 3",
      "exam": "Podiatry service"
    },
    {
      "count": 25,
      "status": "IN_PROGRESS",
      "walk-in": true,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC PODIATRY SERVICES",
      "agenda": "MHC PODIATRY CORE CLINIC 2",
      "exam": "Podiatry service"
    },
    {
      "count": 32,
      "status": "IN_PROGRESS",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC PODIATRY SERVICES",
      "agenda": "MHC  PODIATRY CORE CLINIC 1",
      "exam": "Podiatry service"
    },
    {
      "count": 46,
      "status": "IN_PROGRESS",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC PODIATRY SERVICES",
      "agenda": "MHC PODIATRY CORE CLINIC 2",
      "exam": "Podiatry service"
    },
    {
      "count": 61,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC PODIATRY SERVICES",
      "agenda": "MHC  PODIATRY CORE CLINIC 1",
      "exam": "Podiatry service"
    },
    {
      "count": 6,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC PODIATRY SERVICES",
      "agenda": "MHC PODIATRY CORE CLINIC 2",
      "exam": "Podiatry service"
    },
    {
      "count": 55,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC PODIATRY SERVICES",
      "agenda": "MHC PODIATRY CORE CLINIC 3",
      "exam": "Podiatry service"
    },
    {
      "count": 99,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC SPEECH LANGUAGE SERVICES",
      "agenda": "MHC SPEECH LANG. (MS. T. CAMILLERI)",
      "exam": "Speech and language therapy service"
    },
    {
      "count": 59,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC SPEECH LANGUAGE SERVICES",
      "agenda": "MHC SPEECH LANG. (MS. T. CAMILLERI)",
      "exam": "Speech and language therapy service"
    },
    {
      "count": 66,
      "status": "IN_PROGRESS",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC SPEECH LANGUAGE SERVICES",
      "agenda": "MHC SPEECH LANG. (MS. T. CAMILLERI)",
      "exam": "Speech and language therapy service"
    },
    {
      "count": 10,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC SPEECH LANGUAGE SERVICES",
      "agenda": "MHC SPEECH LANG. (MS. T. CAMILLERI)",
      "exam": "Speech and language therapy service"
    },
    {
      "count": 98,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC TREATMENT ROOM",
      "agenda": "MHC 732 ACC CLINIC",
      "exam": "Anticoagulant control test"
    },
    {
      "count": 23,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC TREATMENT ROOM",
      "agenda": "MHC 754 TREATMENT CLINIC",
      "exam": "Assessment of condition requiring immediate care"
    },
    {
      "count": 36,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC TREATMENT ROOM",
      "agenda": "MHC 43 BLOOD INVESTIGATIONS",
      "exam": "Venous blood sampling"
    },
    {
      "count": 90,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC TREATMENT ROOM",
      "agenda": "MHC 732 ACC CLINIC",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 80,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC TREATMENT ROOM",
      "agenda": "MHC 42 EAR SYRINGING",
      "exam": "Syringing of ear"
    },
    {
      "count": 28,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC TREATMENT ROOM",
      "agenda": "MHC 43 BLOOD INVESTIGATIONS",
      "exam": "Venous blood sampling"
    },
    {
      "count": 46,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC TREATMENT ROOM",
      "agenda": "MHC 732 ACC CLINIC",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 78,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC TREATMENT ROOM",
      "agenda": "MHC 42 EAR SYRINGING",
      "exam": "Syringing of ear"
    },
    {
      "count": 99,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC TREATMENT ROOM",
      "agenda": "MHC 43 BLOOD INVESTIGATIONS",
      "exam": "Venous blood sampling"
    },
    {
      "count": 24,
      "status": "FINISHED",
      "walk-in": true,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC TREATMENT ROOM",
      "agenda": "MHC 754 TREATMENT CLINIC",
      "exam": "Assessment of condition requiring immediate care"
    },
    {
      "count": 4,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC TREATMENT ROOM",
      "agenda": "MHC 732 ACC CLINIC",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 84,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC TREATMENT ROOM",
      "agenda": "MHC 42 EAR SYRINGING",
      "exam": "Syringing of ear"
    },
    {
      "count": 96,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC TREATMENT ROOM",
      "agenda": "MHC 43 BLOOD INVESTIGATIONS",
      "exam": "Venous blood sampling"
    },
    {
      "count": 97,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC WOMAN'S HEALTH CLINIC",
      "agenda": "MHC OBS. GYN CL.",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 40,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC WOMAN'S HEALTH CLINIC",
      "agenda": "MHC OBS. GYN CL.",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 25,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC WOMAN'S HEALTH CLINIC",
      "agenda": "MH2132 MHC CERVICAL SCREENING PR.",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 3,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC WOMAN'S HEALTH CLINIC",
      "agenda": "MHC OBG. POST NAT.",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 73,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC WOMAN'S HEALTH CLINIC",
      "agenda": "MHC OBS. GYN CL.",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 83,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC WOMAN'S HEALTH CLINIC",
      "agenda": "MHC OBST. CL. ANTEN.",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 75,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Mosta Health Centre",
      "clinic": "MHC WOUND MANAGEMENT CLINIC",
      "agenda": "MHC 1339 CHANGE OF DRESSING CLINIC",
      "exam": "Change of dressing"
    },
    {
      "count": 74,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Msida Community Clinic",
      "clinic": "MSIDA PODIATRY SERVICES",
      "agenda": "MSIDA POD CORE CL",
      "exam": "Podiatry service"
    },
    {
      "count": 89,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Naxxar Community Clinic",
      "clinic": "NAXXAR COVID-19 VRS",
      "agenda": "NAXXAR COVID VRS LIST",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 4,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC ANTICOAGULATION CLINIC",
      "agenda": "PHC ACC POC",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 63,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC ANTICOAGULATION CLINIC",
      "agenda": "PHC ACC POC",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 26,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC COVID-19 IMMUNISATION SERVICE",
      "agenda": "PHC ASTRAZENECA COVID IMMUNISATION",
      "exam": "COVID-19 vaccination"
    },
    {
      "count": 74,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC COVID-19 IMMUNISATION SERVICE",
      "agenda": "PHC ASTRAZENECA COVID IMMUNISATION",
      "exam": "COVID-19 vaccination"
    },
    {
      "count": 3,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC COVID-19 IMMUNISATION SERVICE",
      "agenda": "PHC COVID IMMUNISATION",
      "exam": "COVID-19 vaccination"
    },
    {
      "count": 99,
      "status": "FINISHED",
      "walk-in": true,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC COVID-19 IMMUNISATION SERVICE",
      "agenda": "PHC ASTRAZENECA COVID IMMUNISATION",
      "exam": "COVID-19 vaccination"
    },
    {
      "count": 45,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC COVID-19 IMMUNISATION SERVICE",
      "agenda": "PHC ASTRAZENECA COVID IMMUNISATION",
      "exam": "COVID-19 vaccination"
    },
    {
      "count": 78,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC COVID-19 IMMUNISATION SERVICE",
      "agenda": "PHC ASTRAZENECA COVID IMMUNISATION",
      "exam": "COVID-19 vaccination"
    },
    {
      "count": 45,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC COVID-19 VRS",
      "agenda": "PHC COVID VRS LIST",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 94,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC DIABETES CLINIC",
      "agenda": "PHC DIABETIC GENERAL",
      "exam": "Diabetes mellitus service"
    },
    {
      "count": 98,
      "status": "FINISHED",
      "walk-in": true,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC DIABETES CLINIC",
      "agenda": "PHC DIABETIC GENERAL",
      "exam": "Diabetes mellitus service"
    },
    {
      "count": 11,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC DIABETES CLINIC",
      "agenda": "PHC DIABETIC GENERAL",
      "exam": "Diabetes mellitus service"
    },
    {
      "count": 31,
      "status": "IN_PROGRESS",
      "walk-in": null,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC DIABETES CLINIC",
      "agenda": "PHC DIABETIC GENERAL",
      "exam": "Diabetes mellitus service"
    },
    {
      "count": 41,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC DIABETES CLINIC",
      "agenda": "PHC DIABETIC GENERAL",
      "exam": "Diabetes mellitus service"
    },
    {
      "count": 79,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC GP CLINIC",
      "agenda": "PHC 757 GP CLINIC",
      "exam": "Encounter for problem"
    },
    {
      "count": 75,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC GP CLINIC",
      "agenda": "PHC 757 GP CLINIC",
      "exam": "Telephone consultation"
    },
    {
      "count": 79,
      "status": "FINISHED",
      "walk-in": true,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC GP CLINIC",
      "agenda": "PHC 757 GP CLINIC",
      "exam": "Encounter for problem"
    },
    {
      "count": 72,
      "status": "FINISHED",
      "walk-in": true,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC GP CLINIC",
      "agenda": "PHC 757 GP CLINIC",
      "exam": "Telephone consultation"
    },
    {
      "count": 50,
      "status": "IN_PROGRESS",
      "walk-in": true,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC GP CLINIC",
      "agenda": "PHC 757 GP CLINIC",
      "exam": "Encounter for problem"
    },
    {
      "count": 12,
      "status": "IN_PROGRESS",
      "walk-in": true,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC GP CLINIC",
      "agenda": "PHC 757 GP CLINIC",
      "exam": "Telephone consultation"
    },
    {
      "count": 28,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC PHYSIOTHERAPY SERVICES",
      "agenda": "PHC PHYSIO MS DESIRA - FOLLOW UP",
      "exam": "Physiotherapy - Follow up"
    },
    {
      "count": 47,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC PHYSIOTHERAPY SERVICES",
      "agenda": "PHC PHYSIO MS. LUGHERMO CORSO - FOLLOW UP",
      "exam": "Physiotherapy - Follow up"
    },
    {
      "count": 6,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC PHYSIOTHERAPY SERVICES",
      "agenda": "PHC MS LUGHERMO CORSO - NEW CASES",
      "exam": "Physiotherapy - New Case"
    },
    {
      "count": 45,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC PHYSIOTHERAPY SERVICES",
      "agenda": "PHC PHYSIO MR CARDELLO - FOLLOW UP",
      "exam": "Physiotherapy - Follow up"
    },
    {
      "count": 53,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC PHYSIOTHERAPY SERVICES",
      "agenda": "PHC PHYSIO ROTATION 1 - FOLLOW UP",
      "exam": "Physiotherapy - Follow up"
    },
    {
      "count": 90,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC PHYSIOTHERAPY SERVICES",
      "agenda": "PHC PHYSIO ROTATION 1 - NEW CASES",
      "exam": "Physiotherapy - New Case"
    },
    {
      "count": 77,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC PHYSIOTHERAPY SERVICES",
      "agenda": "PHC PHYSIO ROTATION 1 - FOLLOW UP",
      "exam": "Physiotherapy - Follow up"
    },
    {
      "count": 28,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC PHYSIOTHERAPY SERVICES",
      "agenda": "PHC PHYSIO MR CARDELLO- NEW CASES",
      "exam": "Physiotherapy - New Case"
    },
    {
      "count": 54,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC PHYSIOTHERAPY SERVICES",
      "agenda": "PHC PHYSIO ROTATION 1 - NEW CASES",
      "exam": "Physiotherapy - New Case"
    },
    {
      "count": 36,
      "status": "IN_PROGRESS",
      "walk-in": true,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC PHYSIOTHERAPY SERVICES",
      "agenda": "PHC PHYSIO MS. LUGHERMO CORSO - FOLLOW UP",
      "exam": "Physiotherapy - Follow up"
    },
    {
      "count": 67,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC PHYSIOTHERAPY SERVICES",
      "agenda": "PHC PHYSIO ROTATION 1 - FOLLOW UP",
      "exam": "Physiotherapy - Follow up"
    },
    {
      "count": 30,
      "status": "UNARRIVED",
      "walk-in": null,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC PHYSIOTHERAPY SERVICES",
      "agenda": "PHC PHYSIO MR CARDELLO- NEW CASES",
      "exam": "Physiotherapy - New Case"
    },
    {
      "count": 12,
      "status": "UNARRIVED",
      "walk-in": null,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC PHYSIOTHERAPY SERVICES",
      "agenda": "PHC PHYSIO ROTATION 1 - NEW CASES",
      "exam": "Physiotherapy - New Case"
    },
    {
      "count": 55,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC PODIATRY SERVICES",
      "agenda": "PHC PODIATRY CORE CLINIC 2",
      "exam": "Podiatry service"
    },
    {
      "count": 69,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC PODIATRY SERVICES",
      "agenda": "PHC PODIATRY CORE CLINIC 3",
      "exam": "Podiatry service"
    },
    {
      "count": 83,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC PODIATRY SERVICES",
      "agenda": "PHC PODIATRY CORE CLINIC 1",
      "exam": "Podiatry service"
    },
    {
      "count": 16,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC PODIATRY SERVICES",
      "agenda": "PHC PODIATRY CORE CLINIC 2",
      "exam": "Podiatry service"
    },
    {
      "count": 65,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC PODIATRY SERVICES",
      "agenda": "PHC PODIATRY CORE CLINIC 3",
      "exam": "Podiatry service"
    },
    {
      "count": 21,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC PODIATRY SERVICES",
      "agenda": "PHC POD VASCULAR SESSION",
      "exam": "Doppler ultrasound of vascular structure of lower limb"
    },
    {
      "count": 71,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC PODIATRY SERVICES",
      "agenda": "PHC PODIATRY CORE CLINIC 2",
      "exam": "Podiatry service"
    },
    {
      "count": 97,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC PODIATRY SERVICES",
      "agenda": "PHC PODIATRY CORE CLINIC 3",
      "exam": "Podiatry service"
    },
    {
      "count": 41,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC PODIATRY SERVICES",
      "agenda": "PHC PODIATRY CORE CLINIC 4",
      "exam": "Podiatry service"
    },
    {
      "count": 10,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC PODIATRY SERVICES",
      "agenda": "PHC POD VASCULAR SESSION",
      "exam": "Doppler ultrasound of vascular structure of lower limb"
    },
    {
      "count": 47,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC PODIATRY SERVICES",
      "agenda": "PHC PODIATRY CORE CLINIC 1",
      "exam": "Podiatry service"
    },
    {
      "count": 20,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC PODIATRY SERVICES",
      "agenda": "PHC PODIATRY CORE CLINIC 2",
      "exam": "Podiatry service"
    },
    {
      "count": 81,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC PODIATRY SERVICES",
      "agenda": "PHC PODIATRY CORE CLINIC 3",
      "exam": "Podiatry service"
    },
    {
      "count": 3,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC PODIATRY SERVICES",
      "agenda": "PHC PODIATRY CORE CLINIC 4",
      "exam": "Podiatry service"
    },
    {
      "count": 54,
      "status": "UNARRIVED",
      "walk-in": null,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC PODIATRY SERVICES",
      "agenda": "PHC POD VASCULAR SESSION",
      "exam": "Doppler ultrasound of vascular structure of lower limb"
    },
    {
      "count": 42,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC RENAL PREVENTION CLINIC",
      "agenda": "PHC 1352 RENAL PREVENTION CLINIC",
      "exam": "Renal care assessment"
    },
    {
      "count": 42,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC RENAL PREVENTION CLINIC",
      "agenda": "PHC 1352 RENAL PREVENTION CLINIC",
      "exam": "Renal care assessment"
    },
    {
      "count": 25,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC SPEECH LANGUAGE SERVICES",
      "agenda": "PHC SPEECH LANG. (MS. MARIETTE BORG)",
      "exam": "Speech and language therapy service"
    },
    {
      "count": 49,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC SPEECH LANGUAGE SERVICES",
      "agenda": "PHC SPEECH LANG. (MS. C. ZAMMIT)",
      "exam": "Speech and language therapy service"
    },
    {
      "count": 74,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC SPEECH LANGUAGE SERVICES",
      "agenda": "PHC SPEECH LANG. (MS. C. ZAMMIT)",
      "exam": "Speech and language therapy service"
    },
    {
      "count": 54,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC SPEECH LANGUAGE SERVICES",
      "agenda": "PHC SPEECH LANG. (MS. C. ZAMMIT)",
      "exam": "Speech and language therapy service"
    },
    {
      "count": 46,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC SPEECH LANGUAGE SERVICES",
      "agenda": "PHC SPEECH LANG. (MS. MARIETTE BORG)",
      "exam": "Speech and language therapy service"
    },
    {
      "count": 38,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC TREATMENT ROOM",
      "agenda": "PHC 756 TREATMENT CLINIC",
      "exam": "Assessment of condition requiring immediate care"
    },
    {
      "count": 72,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC TREATMENT ROOM",
      "agenda": "PHC 756 TREATMENT CLINIC",
      "exam": "Removal of skin sutures/clips"
    },
    {
      "count": 15,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC TREATMENT ROOM",
      "agenda": "PHC 569 BLOOD INVESTIGATIONS",
      "exam": "Specimen collection"
    },
    {
      "count": 30,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC TREATMENT ROOM",
      "agenda": "PHC 569 BLOOD INVESTIGATIONS",
      "exam": "Venous blood sampling"
    },
    {
      "count": 30,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC TREATMENT ROOM",
      "agenda": "PHC 733 ACC CLINIC",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 87,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC TREATMENT ROOM",
      "agenda": "PHC 566 EAR SYRINGING CLINIC",
      "exam": "Syringing of ear"
    },
    {
      "count": 24,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC TREATMENT ROOM",
      "agenda": "PHC 569 BLOOD INVESTIGATIONS",
      "exam": "Venous blood sampling"
    },
    {
      "count": 51,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC TREATMENT ROOM",
      "agenda": "PHC 569 BLOOD INVESTIGATIONS",
      "exam": "Venous blood sampling"
    },
    {
      "count": 89,
      "status": "FINISHED",
      "walk-in": true,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC TREATMENT ROOM",
      "agenda": "PHC 756 TREATMENT CLINIC",
      "exam": "Assessment of condition requiring immediate care"
    },
    {
      "count": 98,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC TREATMENT ROOM",
      "agenda": "PHC 733 ACC CLINIC",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 87,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC TREATMENT ROOM",
      "agenda": "PHC 733 ACC CLINIC",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 50,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC TREATMENT ROOM",
      "agenda": "PHC 566 EAR SYRINGING CLINIC",
      "exam": "Syringing of ear"
    },
    {
      "count": 65,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC TREATMENT ROOM",
      "agenda": "PHC 569 BLOOD INVESTIGATIONS",
      "exam": "Venous blood sampling"
    },
    {
      "count": 12,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC WOUND MANAGEMENT CLINIC",
      "agenda": "PHC 999 CHANGE OF DRESSING CLINIC",
      "exam": "Change of dressing"
    },
    {
      "count": 89,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Paola Health Centre",
      "clinic": "PHC X-RAY ROOM",
      "agenda": "PHC 595 (RADIOGRAPHY CLINIC)",
      "exam": "To take X-Ray"
    },
    {
      "count": 82,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Pieta Community Clinic",
      "clinic": "PIETA' COVID-19 VRS",
      "agenda": "PIETA' COVID VRS LIST",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 60,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Pieta Community Clinic",
      "clinic": "PIETA PHLEBOTOMY SERVICE",
      "agenda": "PIETA BLOOD LETTING",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 17,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Pieta Community Clinic",
      "clinic": "PIETA PHLEBOTOMY SERVICE",
      "agenda": "PIETA BLOOD LETTING",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 98,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC ANTICOAGULATION CLINIC",
      "agenda": "QHC ACC POC",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 1,
      "status": "IN_PROGRESS",
      "walk-in": null,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC COVID-19 IMMUNISATION SERVICE",
      "agenda": "QHC ASTRAZENECA COVID IMMUNISATION",
      "exam": "COVID-19 vaccination"
    },
    {
      "count": 34,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC COVID-19 IMMUNISATION SERVICE",
      "agenda": "QHC ASTRAZENECA COVID IMMUNISATION",
      "exam": "COVID-19 vaccination"
    },
    {
      "count": 18,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC COVID-19 VRS",
      "agenda": "QHC COVID VRS LIST",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 42,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC DIABETES CLINIC",
      "agenda": "QHC DIABETIC GENERAL",
      "exam": "Diabetes mellitus service"
    },
    {
      "count": 13,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC DIABETES CLINIC",
      "agenda": "QHC DIABETIC GENERAL",
      "exam": "Diabetes mellitus service"
    },
    {
      "count": 88,
      "status": "FINISHED",
      "walk-in": true,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC DIABETES CLINIC",
      "agenda": "QHC DIABETIC GENERAL",
      "exam": "Diabetes mellitus service"
    },
    {
      "count": 37,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC DIABETES CLINIC",
      "agenda": "QHC DIABETIC GENERAL",
      "exam": "Diabetes mellitus service"
    },
    {
      "count": 27,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC DIABETES CLINIC",
      "agenda": "QHC DIABETIC GENERAL",
      "exam": "Diabetes mellitus service"
    },
    {
      "count": 89,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC GP CLINIC",
      "agenda": "QHC 767 GP CLINIC",
      "exam": "Encounter for problem"
    },
    {
      "count": 22,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC GP CLINIC",
      "agenda": "QHC 767 GP CLINIC",
      "exam": "Telephone consultation"
    },
    {
      "count": 43,
      "status": "FINISHED",
      "walk-in": true,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC GP CLINIC",
      "agenda": "QHC 767 GP CLINIC",
      "exam": "Encounter for problem"
    },
    {
      "count": 81,
      "status": "FINISHED",
      "walk-in": true,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC GP CLINIC",
      "agenda": "QHC 767 GP CLINIC",
      "exam": "Telephone consultation"
    },
    {
      "count": 15,
      "status": "IN_PROGRESS",
      "walk-in": true,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC GP CLINIC",
      "agenda": "QHC 767 GP CLINIC",
      "exam": "Encounter for problem"
    },
    {
      "count": 93,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC MENTAL HEALTH SERVICES",
      "agenda": "QHC DR.V.BALDAC MENTAL HEALTH",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 71,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC MENTAL HEALTH SERVICES",
      "agenda": "QHC DR.V.BALDAC MENTAL HEALTH",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 4,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC MENTAL HEALTH SERVICES",
      "agenda": "QHC PSY MULTIDISC.",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 83,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC ORTHOPAEDIC CLINIC",
      "agenda": "QHC ORT MR.A.SULTANA FUP",
      "exam": "Orthopaedic assessment"
    },
    {
      "count": 54,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC ORTHOPAEDIC CLINIC",
      "agenda": "QHC ORT MR.A.SULTANA NEW",
      "exam": "Orthopaedic assessment"
    },
    {
      "count": 47,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC ORTHOPAEDIC CLINIC",
      "agenda": "QHC ORT MR.A.SULTANA FUP",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 80,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC ORTHOPAEDIC CLINIC",
      "agenda": "QHC ORT MR.A.SULTANA NEW",
      "exam": "Orthopaedic assessment"
    },
    {
      "count": 86,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC ORTHOPAEDIC CLINIC",
      "agenda": "QHC ORT MR.A.SULTANA FUP",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 1,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC ORTHOPAEDIC CLINIC",
      "agenda": "QHC ORT MR.A.SULTANA NEW",
      "exam": "Orthopaedic assessment"
    },
    {
      "count": 65,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC PHYSIOTHERAPY SERVICES",
      "agenda": "QHC PHYSIO CL 2 - FOLLOW-UP",
      "exam": "Physiotherapy - Follow up"
    },
    {
      "count": 3,
      "status": "FINISHED",
      "walk-in": true,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC PHYSIOTHERAPY SERVICES",
      "agenda": "QHC PHYSIO DAY CASES - FOLLOW-UP",
      "exam": "Physiotherapy - Follow up"
    },
    {
      "count": 78,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC PHYSIOTHERAPY SERVICES",
      "agenda": "QHC PHYSIO CL 1 - FOLLOW-UP",
      "exam": "Physiotherapy - Follow up"
    },
    {
      "count": 54,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC PHYSIOTHERAPY SERVICES",
      "agenda": "QHC PHYSIO CL 2 - FOLLOW-UP",
      "exam": "Physiotherapy - Follow up"
    },
    {
      "count": 33,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC PHYSIOTHERAPY SERVICES",
      "agenda": "QHC PHYSIO DAY CASES - FOLLOW-UP",
      "exam": "Physiotherapy - Follow up"
    },
    {
      "count": 77,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC PHYSIOTHERAPY SERVICES",
      "agenda": "QHC PHYSIO CL 2 - NEW CASES",
      "exam": "Physiotherapy - New Case"
    },
    {
      "count": 9,
      "status": "UNARRIVED",
      "walk-in": null,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC PHYSIOTHERAPY SERVICES",
      "agenda": "QHC PHYSIO CL 2 - NEW CASES",
      "exam": "Physiotherapy - New Case"
    },
    {
      "count": 60,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC PODIATRY SERVICES",
      "agenda": "QHC PODIATRY CORE CLINIC 1",
      "exam": "Podiatry service"
    },
    {
      "count": 66,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC PODIATRY SERVICES",
      "agenda": "QHC PODIATRY CORE CLINIC 1",
      "exam": "Podiatry service"
    },
    {
      "count": 7,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC PODIATRY SERVICES",
      "agenda": "QHC PODIATRY CORE CLINIC 2",
      "exam": "Podiatry service"
    },
    {
      "count": 31,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC PODIATRY SERVICES",
      "agenda": "QHC PODIATRY CORE CLINIC 1",
      "exam": "Podiatry service"
    },
    {
      "count": 85,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC PODIATRY SERVICES",
      "agenda": "QHC PODIATRY CORE CLINIC 2",
      "exam": "Podiatry service"
    },
    {
      "count": 53,
      "status": "IN_PROGRESS",
      "walk-in": null,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC PODIATRY SERVICES",
      "agenda": "QHC PODIATRY CORE CLINIC 1",
      "exam": "Podiatry service"
    },
    {
      "count": 54,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC PODIATRY SERVICES",
      "agenda": "QHC PODIATRY CORE CLINIC 1",
      "exam": "Podiatry service"
    },
    {
      "count": 86,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC PODIATRY SERVICES",
      "agenda": "QHC PODIATRY CORE CLINIC 2",
      "exam": "Podiatry service"
    },
    {
      "count": 70,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC TREATMENT ROOM",
      "agenda": "QHC 766 TREATMENT ROOM",
      "exam": "Assessment of condition requiring immediate care"
    },
    {
      "count": 42,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC TREATMENT ROOM",
      "agenda": "QHC 151 BLOOD INVESTIGATIONS",
      "exam": "Venous blood sampling"
    },
    {
      "count": 19,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC TREATMENT ROOM",
      "agenda": "QHC 661 ACC CLINIC",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 19,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC TREATMENT ROOM",
      "agenda": "QHC 153 EAR SYRINGING",
      "exam": "Syringing of ear"
    },
    {
      "count": 5,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC TREATMENT ROOM",
      "agenda": "QHC 151 BLOOD INVESTIGATIONS",
      "exam": "Venous blood sampling"
    },
    {
      "count": 37,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC TREATMENT ROOM",
      "agenda": "QHC 151 BLOOD INVESTIGATIONS",
      "exam": "Venous blood sampling"
    },
    {
      "count": 6,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC TREATMENT ROOM",
      "agenda": "QHC 661 ACC CLINIC",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 20,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC TREATMENT ROOM",
      "agenda": "QHC 153 EAR SYRINGING",
      "exam": "Syringing of ear"
    },
    {
      "count": 13,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC TREATMENT ROOM",
      "agenda": "QHC 151 BLOOD INVESTIGATIONS",
      "exam": "Venous blood sampling"
    },
    {
      "count": 37,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC WOUND MANAGEMENT CLINIC",
      "agenda": "CHANGE OF DRESSING CLINIC",
      "exam": "Change of dressing"
    },
    {
      "count": 8,
      "status": "IN_PROGRESS",
      "walk-in": true,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC WOUND MANAGEMENT CLINIC",
      "agenda": "CHANGE OF DRESSING CLINIC",
      "exam": "Change of dressing"
    },
    {
      "count": 27,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC WOUND MANAGEMENT CLINIC",
      "agenda": "CHANGE OF DRESSING CLINIC",
      "exam": "Change of dressing"
    },
    {
      "count": 82,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Qormi Health Centre",
      "clinic": "QHC X-RAY ROOM",
      "agenda": "QHC 221 RADIOLOGY CLINIC",
      "exam": "To take X-Ray"
    },
    {
      "count": 90,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Rabat Health Centre",
      "clinic": "RHC ANTICOAGULATION CLINIC",
      "agenda": "RHC ANTICOAGULANT CLINIC",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 31,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Rabat Health Centre",
      "clinic": "RHC COVID-19 IMMUNISATION SERVICE",
      "agenda": "RHC ASTRAZENECA COVID IMMUNISATION",
      "exam": "COVID-19 vaccination"
    },
    {
      "count": 85,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Rabat Health Centre",
      "clinic": "RHC COVID-19 IMMUNISATION SERVICE",
      "agenda": "RHC ASTRAZENECA COVID IMMUNISATION",
      "exam": "COVID-19 vaccination"
    },
    {
      "count": 79,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Rabat Health Centre",
      "clinic": "RHC COVID-19 IMMUNISATION SERVICE",
      "agenda": "RHC COVID IMMUNISATION",
      "exam": "COVID-19 vaccination"
    },
    {
      "count": 78,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Rabat Health Centre",
      "clinic": "RHC COVID-19 IMMUNISATION SERVICE",
      "agenda": "RHC ASTRAZENECA COVID IMMUNISATION",
      "exam": "COVID-19 vaccination"
    },
    {
      "count": 38,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Rabat Health Centre",
      "clinic": "RHC COVID-19 IMMUNISATION SERVICE",
      "agenda": "RHC ASTRAZENECA COVID IMMUNISATION",
      "exam": "COVID-19 vaccination"
    },
    {
      "count": 3,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Rabat Health Centre",
      "clinic": "RHC COVID-19 VRS",
      "agenda": "RHC COVID VRS LIST",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 2,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Rabat Health Centre",
      "clinic": "RHC DIABETES CLINIC",
      "agenda": "RHC DIABETIC GENERAL",
      "exam": "Diabetes mellitus service"
    },
    {
      "count": 26,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Rabat Health Centre",
      "clinic": "RHC DIABETES CLINIC",
      "agenda": "RHC DIABETIC GENERAL",
      "exam": "Diabetes mellitus service"
    },
    {
      "count": 37,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Rabat Health Centre",
      "clinic": "RHC GP CLINIC",
      "agenda": "RHC 761 GP CLINIC",
      "exam": "Encounter for problem"
    },
    {
      "count": 93,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Rabat Health Centre",
      "clinic": "RHC GP CLINIC",
      "agenda": "RHC 761 GP CLINIC",
      "exam": "Telephone consultation"
    },
    {
      "count": 34,
      "status": "FINISHED",
      "walk-in": true,
      "organization unit": "Rabat Health Centre",
      "clinic": "RHC GP CLINIC",
      "agenda": "RHC 761 GP CLINIC",
      "exam": "Encounter for problem"
    },
    {
      "count": 89,
      "status": "FINISHED",
      "walk-in": true,
      "organization unit": "Rabat Health Centre",
      "clinic": "RHC GP CLINIC",
      "agenda": "RHC 761 GP CLINIC",
      "exam": "Telephone consultation"
    },
    {
      "count": 69,
      "status": "IN_PROGRESS",
      "walk-in": true,
      "organization unit": "Rabat Health Centre",
      "clinic": "RHC GP CLINIC",
      "agenda": "RHC 761 GP CLINIC",
      "exam": "Encounter for problem"
    },
    {
      "count": 93,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Rabat Health Centre",
      "clinic": "RHC PODIATRY SERVICES",
      "agenda": "RHC PODIATRY CORE CLINIC 2",
      "exam": "Podiatry service"
    },
    {
      "count": 81,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Rabat Health Centre",
      "clinic": "RHC PODIATRY SERVICES",
      "agenda": "RHC PODIATRY CORE CLINIC 1",
      "exam": "Podiatry service"
    },
    {
      "count": 79,
      "status": "FINISHED",
      "walk-in": true,
      "organization unit": "Rabat Health Centre",
      "clinic": "RHC PODIATRY SERVICES",
      "agenda": "RHC PODIATRY CORE CLINIC 2",
      "exam": "Podiatry service"
    },
    {
      "count": 31,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Rabat Health Centre",
      "clinic": "RHC PODIATRY SERVICES",
      "agenda": "RHC PODIATRY CORE CLINIC 1",
      "exam": "Podiatry service"
    },
    {
      "count": 93,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Rabat Health Centre",
      "clinic": "RHC PODIATRY SERVICES",
      "agenda": "RHC PODIATRY CORE CLINIC 2",
      "exam": "Podiatry service"
    },
    {
      "count": 21,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Rabat Health Centre",
      "clinic": "RHC PODIATRY SERVICES",
      "agenda": "RHC PODIATRY CORE CLINIC 1",
      "exam": "Podiatry service"
    },
    {
      "count": 36,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Rabat Health Centre",
      "clinic": "RHC PODIATRY SERVICES",
      "agenda": "RHC PODIATRY CORE CLINIC 2",
      "exam": "Podiatry service"
    },
    {
      "count": 15,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Rabat Health Centre",
      "clinic": "RHC SPEECH LANGUAGE SERVICES",
      "agenda": "RHC SPEECH LANG. (MR.M.FRENDO)",
      "exam": "Speech and language therapy service"
    },
    {
      "count": 9,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Rabat Health Centre",
      "clinic": "RHC SPORTS CLINIC",
      "agenda": "RHC DR.L.ATTARD SPORTS CLINIC",
      "exam": "Assessment of sports injury"
    },
    {
      "count": 90,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Rabat Health Centre",
      "clinic": "RHC TREATMENT ROOM",
      "agenda": "RHC 698 ACC CLI",
      "exam": "Anticoagulant control test"
    },
    {
      "count": 80,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Rabat Health Centre",
      "clinic": "RHC TREATMENT ROOM",
      "agenda": "RHC 760 TREATMENT CLINIC",
      "exam": "Assessment of condition requiring immediate care"
    },
    {
      "count": 50,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Rabat Health Centre",
      "clinic": "RHC TREATMENT ROOM",
      "agenda": "RHC 253 BLOOD INVESTIGATIONS",
      "exam": "Venous blood sampling"
    },
    {
      "count": 42,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Rabat Health Centre",
      "clinic": "RHC TREATMENT ROOM",
      "agenda": "RHC 698 ACC CLI",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 96,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Rabat Health Centre",
      "clinic": "RHC TREATMENT ROOM",
      "agenda": "RHC 253 BLOOD INVESTIGATIONS",
      "exam": "Venous blood sampling"
    },
    {
      "count": 97,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Rabat Health Centre",
      "clinic": "RHC TREATMENT ROOM",
      "agenda": "RHC 253 BLOOD INVESTIGATIONS",
      "exam": "Venous blood sampling"
    },
    {
      "count": 79,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Rabat Health Centre",
      "clinic": "RHC TREATMENT ROOM",
      "agenda": "RHC 698 ACC CLI",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 41,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Rabat Health Centre",
      "clinic": "RHC TREATMENT ROOM",
      "agenda": "RHC 253 BLOOD INVESTIGATIONS",
      "exam": "Venous blood sampling"
    },
    {
      "count": 56,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Rabat Health Centre",
      "clinic": "RHC WELL BABY CLINIC",
      "agenda": "RHC 876 WELL BABY CLINIC",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 21,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Rabat Health Centre",
      "clinic": "RHC WOUND MANAGEMENT CLINIC",
      "agenda": "RHC 1073 CHANGE OF DRESSING CLINIC",
      "exam": "Change of dressing"
    },
    {
      "count": 40,
      "status": "IN_PROGRESS",
      "walk-in": null,
      "organization unit": "Rabat Health Centre",
      "clinic": "RHC WOUND MANAGEMENT CLINIC",
      "agenda": "RHC 1073 CHANGE OF DRESSING CLINIC",
      "exam": "Change of dressing"
    },
    {
      "count": 66,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Rabat Health Centre",
      "clinic": "RHC WOUND MANAGEMENT CLINIC",
      "agenda": "RHC 1073 CHANGE OF DRESSING CLINIC",
      "exam": "Change of dressing"
    },
    {
      "count": 63,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Sciortino Practice",
      "clinic": "SCIORTINO PRACTICE PAOLA GP",
      "agenda": "DR. MARTHESE SCIORTINO PAOLA",
      "exam": "Cervical smear test"
    },
    {
      "count": 69,
      "status": "ARRIVED",
      "walk-in": null,
      "organization unit": "Sciortino Practice",
      "clinic": "SCIORTINO PRACTICE PAOLA GP",
      "agenda": "DR. MARTHESE SCIORTINO PAOLA",
      "exam": "Encounter for problem"
    },
    {
      "count": 42,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Sciortino Practice",
      "clinic": "SCIORTINO PRACTICE PAOLA GP",
      "agenda": "DR. MARTHESE SCIORTINO PAOLA",
      "exam": "Venous blood sampling"
    },
    {
      "count": 38,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Sciortino Practice",
      "clinic": "SCIORTINO PRACTICE PAOLA GP",
      "agenda": "DR. MARTHESE SCIORTINO PAOLA",
      "exam": "Encounter for problem"
    },
    {
      "count": 43,
      "status": "FINISHED",
      "walk-in": null,
      "organization unit": "Sciortino Practice",
      "clinic": "SCIORTINO PRACTICE PAOLA GP",
      "agenda": "DR. MARTHESE SCIORTINO PAOLA",
      "exam": "Venous blood sampling"
    },
    {
      "count": 26,
      "status": "IN_PROGRESS",
      "walk-in": null,
      "organization unit": "Sciortino Practice",
      "clinic": "SCIORTINO PRACTICE PAOLA GP",
      "agenda": "DR. MARTHESE SCIORTINO PAOLA",
      "exam": "Gynae US"
    },
    {
      "count": 86,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Sciortino Practice",
      "clinic": "SCIORTINO PRACTICE PAOLA GP",
      "agenda": "DR. MARTHESE SCIORTINO PAOLA",
      "exam": "Cervical smear test"
    },
    {
      "count": 75,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Sciortino Practice",
      "clinic": "SCIORTINO PRACTICE PAOLA GP",
      "agenda": "DR. MARTHESE SCIORTINO PAOLA",
      "exam": "Encounter for problem"
    },
    {
      "count": 49,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Agenzija Sedqa",
      "clinic": "Sedqa COVID-19 Immunisation Service",
      "agenda": "SEDQA COVID IMMUNISATION",
      "exam": "COVID-19 vaccination"
    },
    {
      "count": 18,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Siggiewi Community Clinic",
      "clinic": "SIGGIEWI NURSING SERVICES",
      "agenda": "SIGGIEWI NURSE LED CLINIC",
      "exam": "Telephone consultation"
    },
    {
      "count": 72,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "ST. VINCENT DE PAULE RESIDENCE",
      "clinic": "SVPR COVID-19 VRS",
      "agenda": "SVPR COVID VRS LIST",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 13,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Ta Xbiex Community Clinic",
      "clinic": "TA' XBIEX PODIATRY SERVICES",
      "agenda": "TA XBIEX POD CORE CL",
      "exam": "Podiatry service"
    },
    {
      "count": 70,
      "status": "FINISHED",
      "walk-in": true,
      "organization unit": "The Family Clinic Siggiewi",
      "clinic": "TFC SIGGIEWI GP SERVICE",
      "agenda": "DR.CARMEN SAMMUT SIGGIEWI",
      "exam": "Encounter for problem"
    },
    {
      "count": 39,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Victoria Health Centre",
      "clinic": "VHC COVID-19 VRS",
      "agenda": "VHC COVID VRS LIST",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 33,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Victoria Health Centre",
      "clinic": "VHC COVID-19 VRS",
      "agenda": "VHC COVID VRS LIST",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 87,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Victoria Health Centre",
      "clinic": "VHC GP CLINIC",
      "agenda": "GGH HC CLINIC 61 (GP CLINIC)",
      "exam": "Encounter for problem"
    },
    {
      "count": 92,
      "status": "FINISHED",
      "walk-in": true,
      "organization unit": "Victoria Health Centre",
      "clinic": "VHC GP CLINIC",
      "agenda": "GGH HC CLINIC 61 (GP CLINIC)",
      "exam": "Encounter for problem"
    },
    {
      "count": 67,
      "status": "FINISHED",
      "walk-in": true,
      "organization unit": "Victoria Health Centre",
      "clinic": "VHC GP CLINIC",
      "agenda": "GGH HC CLINIC 61 (GP CLINIC)",
      "exam": "Telephone consultation"
    },
    {
      "count": 6,
      "status": "IN_PROGRESS",
      "walk-in": true,
      "organization unit": "Victoria Health Centre",
      "clinic": "VHC GP CLINIC",
      "agenda": "GGH HC CLINIC 61 (GP CLINIC)",
      "exam": "Encounter for problem"
    },
    {
      "count": 18,
      "status": "ARRIVED",
      "walk-in": true,
      "organization unit": "Victoria Health Centre",
      "clinic": "VHC TREATMENT ROOM",
      "agenda": "GOZO H.C. (TREATMENT CLINIC)",
      "exam": "Assessment of condition requiring immediate care"
    },
    {
      "count": 31,
      "status": "FINISHED",
      "walk-in": true,
      "organization unit": "Victoria Health Centre",
      "clinic": "VHC TREATMENT ROOM",
      "agenda": "GOZO H.C. (TREATMENT CLINIC)",
      "exam": "Assessment of condition requiring immediate care"
    },
    {
      "count": 31,
      "status": "IN_PROGRESS",
      "walk-in": true,
      "organization unit": "Victoria Health Centre",
      "clinic": "VHC TREATMENT ROOM",
      "agenda": "GOZO H.C. (TREATMENT CLINIC)",
      "exam": "Assessment of condition requiring immediate care"
    },
    {
      "count": 95,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Vittoriosa Community Clinic",
      "clinic": "VITTORIOSA COVID-19 VRS",
      "agenda": "VITTORIOSA COVID VRS LIST",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 44,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Zabbar Community Clinic",
      "clinic": "ZABBAR COVID-19 VRS",
      "agenda": "ZABBAR COVID VRS LIST",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 59,
      "status": "CANCELLED",
      "walk-in": null,
      "organization unit": "Zabbar Community Clinic",
      "clinic": "ZABBAR PHLEBOTOMY SERVICE",
      "agenda": "ZABBAR BLOOD LETTING",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 20,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Zabbar Community Clinic",
      "clinic": "ZABBAR PHLEBOTOMY SERVICE",
      "agenda": "ZABBAR BLOOD LETTING",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 82,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Zebbug Community Clinic",
      "clinic": "ZEBBUG COVID-19 VRS",
      "agenda": "ZEBBUG COVID VRS LIST",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 18,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Zebbug Community Clinic",
      "clinic": "ZEBBUG NURSING SERVICES",
      "agenda": "ZEBBUG NURSE LED CLINIC",
      "exam": "Assessment of well being"
    },
    {
      "count": 96,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Zejtun Community Clinic",
      "clinic": "ZEJTUN COVID-19 IMMUNISATION SERVICE",
      "agenda": "ZEJTUN ASTRAZENECA COVID IMMUNISATION",
      "exam": "COVID-19 vaccination"
    },
    {
      "count": 45,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Zejtun Community Clinic",
      "clinic": "ZEJTUN COVID-19 VRS",
      "agenda": "ZEJTUN COVID VRS LIST",
      "exam": "CPAS Imported Exam"
    },
    {
      "count": 47,
      "status": "PLANNED",
      "walk-in": null,
      "organization unit": "Zurrieq Community Clinic",
      "clinic": "ZURRIEQ COVID-19 VRS",
      "agenda": "ZURRIEQ COVID VRS LIST",
      "exam": "CPAS Imported Exam"
    }
   ]
};